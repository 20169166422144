.viewPerscriptionPopupDoctor {
    .verification_card {
        max-width: 700px !important;
        min-width: 600px !important;
    }

    &__title {
        font-family: 'Frank Ruhl Libre', serif;
        font-size: 42px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }

    &__patient_name {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }

    &__image_container {
        padding: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

@media (max-width: $mobile) {
    .viewPerscriptionPopupDoctor {
        .verification_card {
            max-width: $mobile !important;
            min-width: initial !important;
        }
    }
}