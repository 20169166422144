.previous__nav {
  @extend .p-s;
  @extend .m-b-xs;

  background: white;

  &>div {
    @extend .m-b-s;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &>nav {
    @extend .p-b-xs;

    overflow-x: auto;

    /* Track */
    &::-webkit-scrollbar {
      height: 2px;
      background: $light-grey;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $blue;
    }

    ul {
      list-style: none;
      display: flex;

      li {
        @extend .m-r-s;
        @extend .p-b-xxs;
        @extend .p5;

        &:hover {
          cursor: pointer;
        }

        color: $mid-grey;
        white-space: nowrap;
        border-bottom: 2px solid white;
        transition: border-bottom 0.2s ease-in;
      }

      .active {
        border-bottom: 2px solid $green;
        color: black;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .previous__nav {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    width: 100%;

    &>nav,
    &>div {
      padding-left: $space-xs;
    }

    &__number {
      display: none;
    }
  }
}