.reusable_badge {
  background: #FFE5A4;
  border-radius: 30px;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  margin: auto;
  text-align: center;
  font-size: 12px;
  font-family: $sans-serif;
  font-weight: 500;

  &--red {
    background: #FFE5E6;
    color: #990000;
  }

  &--gray {
    background: #F6F6F6;
    color: #484848;
  }

  &--orange {
    background: orange;
  }

  &--blue {
    background: #E9EDFB;
    color: #3B5EDB;
  }

  &--green {
    background: #F0F4F2;
    color: #415849;
  }

  &--greenProduct {
    background: $green-light;
    color: #1d2922;
  }

  &--yellowProduct {
    background: #F9E7C8;
    color: #944F37;
  }

  &--redProduct {
    background: #FFCECE;
    color: #970000;
  }

  &--greyProduct {
    background: #E5E5EA;
    color: #484848;
  }
}

@media screen and (max-width: $mobile) {
  .reusable_badge {
    font-size: 0.75em;
  }
}