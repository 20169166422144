.order_details_popup {
    width: 100%;
    display: flex;
    justify-content: center;

    &__title {
        font-family: 'Frank Ruhl Libre', serif;
        font-size: 42px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }

    &__patient_name {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }

    .verification_card {
        min-width: 60% !important;
    }
}