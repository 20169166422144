.full_screen_overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0);
  z-index: 999999;
  transition: all 0.3s ease-in-out;
  pointer-events: none;

  .full_screen_overlay__content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }

  .full_screen_overlay__content--show {
    pointer-events: auto;
    opacity: 1;
  }
}

.full_screen_overlay--show {
  background-color: rgba(255, 255, 255, 0.8);
  pointer-events: auto;
}

.full_screen_overlay--show.full_screen_overlay--white {
  background-color: rgba(255, 255, 255, 1);
  pointer-events: auto;
}

@media screen and (max-width: $mobile) {
  .full_screen_overlay {
    .full_screen_overlay__content {
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
}