.login_header_black {
  display: none;
}

.auth_wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/login.png");

  .auth_image {
    display: none;
  }

  .auth {
    @extend .m-v-auto;
    @extend .p-t-l;

    margin-right: 15%;
    width: 400px;
    background: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__header {
      @extend .p-h-m;

      text-align: center;

      h1 {
        @extend .m-b-s;
      }

      .p5 {
        @extend .m-b-m;
      }
    }

    &__reset_success {
      @extend .p-h-m;
      @extend .m-t-l;

      text-align: center;

      img {
        @extend .m-b-m;

        width: 30%;
      }

      h1 {
        @extend .m-b-s;
      }

      .p5 {
        @extend .m-b-m;
        text-align: left;
      }
    }

    &__form {
      @extend .p-h-m;
      @extend .m-b-m;

      width: 100%;
      text-align: center;
    }

    &__buttons {
      @extend .p-h-m;
      @extend .p-b-mm;

      width: 100%;
      text-align: center;

      &>div {
        @extend .m-b-s;
      }
    }

    &__footer {
      @extend .p-v-ss;

      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid $light-grey;

      p {
        @extend .p-r-xxs;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .auth_header_white {
    display: none;
  }

  .auth_header_black {
    display: block;
  }

  .auth_wrapper {
    background: none;
    display: flex;
    flex-direction: column;

    .auth {
      margin-top: 0px;
      width: 100%;
      padding: 0;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: $space-s;
      padding-right: $space-s;

      &__header {
        padding: 0;
        margin-top: $space-m;

        text-align: left;

        h1 {
          font-family: $serif;
          font-weight: bold;
          font-size: 2.25rem;
        }

        .p5 {
          margin-bottom: $space-s;
          font-weight: normal;
        }
      }

      &__form {
        width: 100%;
        padding: 0;
      }

      &__buttons {
        width: 100%;
        padding: 0;
      }
    }
  }
}

.splash {
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__logo {
    @extend .p-v-l
  }

  h1 {
    @extend .m-b-l;
    text-align: center;
  }

  &__info {
    @extend .m-b-s;
    text-align: center;
    font-size: 18px;
    font-weight: 500;

    a {
      font-weight: bold;
      text-decoration: none;
      color: black;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 660px;
    margin: 10px;
  }

}