.account_footer__navigation {
  display: flex;
  justify-content: space-evenly;

  &__group {
    @extend .m-r-xxl;

    flex: 1;
    min-width: 179px;
    margin-right: 10px;

    p {
      @extend .p-b-s;
      @extend .p5--semi-bold;

      padding-bottom: 18px;
    }

    ul {
      list-style: none;

      li {
        @extend .p-b-xs;
        padding-bottom: 3px;

        a {
          @extend .p5;

          text-decoration: none !important;
          color: $off-black !important;
        }

        img {
          max-width: 42px;
          vertical-align: text-top;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .account_footer__navigation {
    flex-direction: column;

    &__group {
      padding-bottom: $space-m;
    }
  }
}