.desktop_order__header {
  @extend .p-h-s;
  @extend .p-v-m;

  line-height: 26px;
  background: white;
  border-bottom: 1px solid $light-grey;
  padding: 0 0 48px;
  /*  position: relative; */

  &__report {
    background-color: $green-light;

    button {
      padding-top: 1rem;
      margin-left: auto;
      padding-right: 1rem;
    }

    /*    position: absolute;
   top: 10px;
   right: 50px; */
  }

  &__icon {
    border-radius: 50%;
    background-color: #00a372;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 22px;
  }

  &__top {
    @extend .m-b-s;

    display: flex;
    flex-wrap: wrap;
    padding: 31px 23px 30px;
    margin-bottom: 31px;

    -webkit-transition: background-color 0.3s ease-in-out;
    -moz-transition: background-color 0.3s ease-in-out;
    -o-transition: background-color 0.3s ease-in-out;
    -ms-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;

    h5 {
      margin-bottom: 18px;
    }

    &__text {
      padding-top: 9px;
    }
  }

  &__top--nopt {
    padding-top: 0px !important;
  }

  &__top.tracked {
    background-color: $green-light;
  }

  &__details {
    display: flex;
    justify-content: space-between;

    &__left {
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  &__text {
    @extend .m-b-m;

    width: 58%;
    padding: 0 23px;
    margin-bottom: 21px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 23px;

    &__left {
      display: flex;

      &>div {
        @extend .m-r-s;
      }
    }

    &__right {
      display: flex;
      align-items: center;
    }
  }
}

.tracking_button {
  display: none;
}

@media screen and (max-width: $mobile) {
  .desktop_order__header {
    display: none;
  }
}