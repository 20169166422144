.contact_number {
  margin-bottom: 28px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;

  div:first-child {
    flex: 0 33%;

    label {
      font-size: 0.75rem;
    }
  }

  div:last-child {
    flex: 0 63%;
  }
}

@media screen and (max-width: $tablet) {
  .contact_number {
    flex-direction: column;

    &>div {
      margin-bottom: 24px;
    }
  }
}