.information_card {
  width: 510px;
  height: auto;
  background: white;
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 3;

  .information_card__close {
    @extend .p-t-s;
    @extend .p-r-s;

    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .information_card__content {
    @extend .p-h-m;
    @extend .p-b-mm;
    @extend .p-t-l;

    .information_card__title {
      width: 90%;
      display: flex;
      flex-direction: column;

      h1 {
        @extend .m-b-s;
      }

      p {
        @extend .m-b-ss;
      }
    }
  }

  &__buttons {
    @extend .w-240;
    @extend .h-48;
  }
}

@media screen and (max-width: $mobile) {
  .information_card {
    width: 100vw;
    height: 100vh;

    .information_card__content {
      padding-left: $space-s;
      padding-right: $space-s;

      padding-top: $space-mm;
      padding-bottom: $space-l;

      .information_card__title {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
      }
    }
  }
}