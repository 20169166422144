.dashboard {
  background: $yellow-light;

  &--doctor {
    background-color: #f6f6f6;
  }

  &__main {
    @extend .p-h-m;
    @extend .p-t-s;

    display: flex;
  }

  //test
  &__status {
    @extend .m-r-s;
    @extend .m-b-s;

    position: -webkit-sticky;
    position: sticky;
    // 56 is the height of dashboard navigation
    // 20 is padding
    top: calc(56px + 20px);
    flex: 25%;
    // 96 is header height
    // 73 is dash nav height,
    min-height: calc(100vh - 96px - 73px - 20px);
    height: fit-content;

    background: white;
    border-radius: 4px;
    overflow: hidden;
  }

  &__status--mobile {
    display: none;
  }

  &__content {
    flex: 61%;
    max-width: 66%;
    margin: 0 auto;

    &__fullwidth {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 1250px) {
  .dashboard {
    &__main {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media (max-width: $tablet) {
  .dashboard {
    &__content {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media (max-width: $mobile) {
  .dashboard {
    &__status {
      z-index: 100000000;
      margin: 0;
      flex: 0;
    }

    &__main {
      padding: 0;
    }
  }
}