.order_payment_form {
    width: 100%;
    padding: 1rem 0px;

    .contact_information__line {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    &__title {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        font-family: $sans-serif;
    }

    &__divider {
        height: 1px;
        background: #787878;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__horizontal_marign {
        padding-left: 60px;
        padding-right: 60px;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1rem;
        border-bottom: 1px solid #D5D5D5;
        margin-bottom: 1rem;

        &__title {
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
            font-family: $sans-serif;
        }

        &__text {
            margin-top: 1rem;
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
            line-height: 26px;
            text-align: center;
            color: #000000;
            font-family: $sans-serif;
        }
    }

    &__payment_failed {
        display: flex;
        align-items: center;
        color: red;
        margin-bottom: 0.7rem;

        span {
            margin-left: 1rem;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 34px;
            font-family: $sans-serif;
        }
    }

    &__confirm_button {
        max-width: 50%;
        margin: auto;
        height: 50px;
    }

    &__terms {
        margin-top: 1rem;
    }

    h5 {
        margin-bottom: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        font-family: $sans-serif;
    }

    .payment_form_ordered_products {
        &__title {
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
            font-family: $sans-serif;
        }

        &__table {
            width: 100%;
            display: flex;
            flex-direction: column;

            &__header {
                display: flex;
                border-bottom: 1px solid rgb(212, 212, 212);
                padding-bottom: 0.5rem;
                margin-bottom: 1rem;

                &__cell {
                    font-family: $sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 30px;
                    text-transform: uppercase;
                    color: #787878;

                    &--center {
                        text-align: center;
                    }

                    &--left {
                        text-align: left;
                    }

                    &--right {
                        text-align: right;
                    }
                }
            }

            &__body {
                display: flex;
                flex-direction: column;

                &__row {
                    display: flex;
                    margin-bottom: 1rem;

                    &:last-of-type {
                        border-bottom: 1px solid rgb(212, 212, 212);
                        padding-bottom: 1rem;
                    }

                    &__cell {
                        font-family: $sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;

                        &--center {
                            text-align: center;
                        }

                        &--left {
                            text-align: left;
                        }

                        &--right {
                            text-align: right;
                        }
                    }
                }
            }
        }

        &__total {
            &__row {
                display: flex;
                justify-content: flex-end;

                span {
                    font-size: 14px !important;
                    line-height: 24px;
                    color: #000000;
                    font-family: $sans-serif;
                    font-weight: 300;
                }

                &:last-of-type() {
                    span {
                        font-size: 18px !important;
                        line-height: 34px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    @media (max-width: $mobile) {
        & {
            padding: 1rem 0px;
        }

        &__confirm_button {
            max-width: 90%;
        }

        &__horizontal_marign {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}