.eligibility_criteria {
  @extend .m-t-xxl;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  display: flex;
  justify-content: center;
  overflow: hidden;

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-mm;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;

    &>h1 {
      @extend .m-t-s;
      @extend .m-b-ss;

      text-align: center;
    }

    .p-5 {
      color: $off-black;
    }
  }

  &__text {
    @extend .m-b-m;
    width: 100%;
  }

  &__group {
    @extend .m-b-m;

    .details_open {
      display: none;
    }

    details {
      &[open] {
        .details_open {
          display: block;
        }

        .details_closed {
          display: none;
        }
      }

      &>ul {
        @extend .m-t-m;
        @extend .m-b-m;

        list-style: none;

        &>li {
          @extend .m-b-xs;

          &::before {
            content: "\2022";
            color: $blue;
            font-weight: bold;
            display: inline-block;
            width: 1em;
          }
        }
      }
    }

    summary {
      @extend .p-b-xs;
      @extend .m-b-s;

      display: flex;
      justify-content: space-between;
      cursor: pointer;
      outline: none;
      border-bottom: 1px solid $light-grey;

      &::-webkit-details-marker {
        display: none;
      }
    }
  }

  &__buttons {
    @extend .m-b-mm;

    width: 100%;
    display: flex;
    justify-content: space-evenly;

    &>div {
      @extend .w-240;
      @extend .h-48;
    }
  }
}

@media screen and (max-width: $tablet) {
  .eligibility_criteria {
    &__buttons {
      width: 100%;
      margin-bottom: $space-ss;
      flex-direction: column;

      &>div {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .eligibility_criteria {
    margin-top: $space-l;

    &__content {
      width: 100%;
      align-items: flex-start;
      padding-left: $space-s;
      padding-right: $space-s;
    }

    &__title {
      align-items: flex-start;
      text-align: left;
      width: 100%;

      h1 {
        font-weight: bold;
        font-family: $serif;
        font-size: 2.25rem;
        text-align: left;
      }
    }

    &__text {
      margin-bottom: $space-s;
      width: 100%;
    }

    &__buttons {
      width: 100%;
      margin-bottom: $space-xl;
      flex-direction: column;

      &>div {
        width: 100%;
      }
    }
  }
}