.auto_complete__options {
  box-shadow: 2px 2px 3px 3px $extra-light-grey;
  position: absolute;
  background: white;
  text-align: center;
  max-height: 300px;
  overflow-y: auto;
  min-width: 100%;

  z-index: 2;

  .auto_complete__option {
    @extend .p-v-s;
    @extend .p-h-xs;

    cursor: pointer;
    min-width: 100%;
    font-family: $sans-serif;
    text-align: left;

    &:hover {
      font-weight: 500;
      background: $extra-light-grey;
    }
  }

  .active_option {
    @extend .label--semi-bold;

    background: $extra-light-grey;
  }
}