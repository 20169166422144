.tips_carousel {

  // styles for dots
  .slick-slider {
    .slick-dots {
      li {
        width: 8px;

        button {
          &::before {
            font-size: 8px;
            color: $dark-grey;
          }
        }
      }
    }
  }

  // styles for arrows
  .slick-arrow.slick-disabled {
    display: none !important;
  }

  .slick-next {
    right: 0 !important;
  }

  .slick-prev {
    z-index: 88;
    left: 0;
  }

  .slick-arrow.slick-next {
    right: 8px !important;

    &::before {
      display: none;
    }
  }

  .slick-arrow.slick-prev {
    padding-left: 18px;

    &::before {
      display: none;
    }
  }

  &__tip {
    outline: none;
    position: relative;
    height: 335px;
    width: 260px;
    color: black;

    &__text--desktop {
      bottom: $space-s;
      position: absolute;
      bottom: 24px;
      left: 24px;
      z-index: 100;
    }

    &__text--mobile {
      @extend .p-b-s;

      display: none;
    }

    &__image {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}

.tips_mobile {
  @extend .m-t-m;

  display: none;

  .p3--semi-bold {
    @extend .m-b-xs;
    @extend .m-t-s;
  }
}

@media screen and (max-width: $mobile) {
  .tips_mobile {
    display: block;
  }

  .slick-arrow {
    display: none !important;
  }

  .tips_carousel {
    position: relative;

    &__tip {
      position: static;
      height: 260px;

      p.label {
        display: none;
      }

      &__number {
        display: none;
      }

      &__text--desktop {
        display: none;
      }

      &__text--mobile {
        display: block;
      }

      &__image {
        width: 100%;
        height: 100%;
        position: static;
        object-fit: cover;
      }
    }
  }
}