.inputValidationBulletPoints {
    display: flex;
    justify-content: space-between;

    &__col {
        flex: 1;
        color: #787878;
        font-family: $sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;

        &__point {
            display: flex;
            align-items: center;

            &--valid {
                color: #1E1E1E;
                ;
            }

            &--invalid {
                color: #787878;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .inputValidationBulletPoints {
        flex-direction: column;
    }
}