// new style guide
.h1-p {
  font-weight: bold;
  font-family: $serif;
  font-size: 4.75rem;
}

.h2-p {
  font-weight: bold;
  font-family: $serif;
  font-size: 3.75rem;
}

.h3-p {
  font-family: $serif;
  font-weight: bold;
  font-size: 3rem;
}

.h4-p {
  font-weight: 500;
  font-family: $sans-serif;
  font-size: 2.5rem;
}

.h4-p-a {
  font-family: $serif;
  font-weight: bold;
  font-size: 2.6rem;
}

.h5-p-a {
  font-family: $serif;
  font-weight: bold;
  font-size: 2.25rem;
}

.h5-p {
  font-weight: 500;
  font-family: $sans-serif;
  font-size: 2rem;
}

.h6-p {
  font-weight: 500;
  font-family: $sans-serif;
  font-size: 1.75rem;
}

.h1-a {
  font-weight: bold;
  font-family: $serif;
  font-size: 3.25rem;
}

.h2-a {
  font-weight: bold;
  font-family: $serif;
  font-size: 2.875rem;
}

.h3-a {
  font-weight: bold;
  font-family: $serif;
  font-size: 2.625rem;
}

.h4-a {
  font-weight: 500;
  font-family: $sans-serif;
  font-size: 2.25rem;
}

.h5-a {
  font-weight: 500;
  font-family: $sans-serif;
  font-size: 2rem;
}

.h5-a--serif {
  font-weight: bold;
  font-family: $serif;
  font-size: 2rem;
}

.h6-a {
  font-weight: 500;
  font-family: $sans-serif;
  font-size: 1.75rem;
}

.h6-a--serif {
  font-weight: bold;
  font-family: $serif;
  font-size: 1.75rem;
}

.h7-a {
  font-weight: 500;
  font-family: $sans-serif;
  font-size: 1.5625rem;
}

.h8-a {
  font-weight: 500;
  font-family: $sans-serif;
  font-size: 1.375rem;
}

.h9-a {
  font-weight: 500;
  font-family: $sans-serif;
  font-size: 1.25rem;
}