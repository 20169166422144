.age_select {
  &__content {
    display: flex;
    justify-content: space-between;

    &>div {
      width: calc(33% - 10px);
    }
  }

  &__error {
    text-align: center;

    &>p {
      color: $error;
    }
  }
}

@media screen and (max-width: $mobile) {
  .age_select {
    &__content {
      flex-direction: column;

      &>div {
        margin-bottom: 24px;

        width: 100%;
      }
    }
  }
}