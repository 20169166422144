.reusable_drop_zone {
  width: 100%;
  height: 100%;
  display: flex;

  &__zone {
    cursor: pointer;
    background: #F6F6F6;
    border: 3px dashed #D5D5D5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 74px 0;

    &:hover {
      background: #F2F2F2;
    }

    svg {
      height: 80px !important;
      width: 80px !important;
      margin-bottom: 1rem !important;
    }

    &__number_of_files {
      font-family: $sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      color: #484848;
    }

    &__text {
      font-family: $sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      text-align: center;
      color: #000000;

      span {
        text-decoration: underline;
        font-family: $sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: blue;
      }
    }
  }
}