.onboarding_stepper_container {
  position: relative;
  height: 4px;
  width: 100%;
  z-index: 0;
}

.onboarding_stepper {
  position: absolute;
  height: 100%;
  width: 100%;
  background: $extra-light-grey;
}

.onboarding_stepper_blue {
  position: absolute;
  z-index: 100;
  height: 100%;
  background: $blue;
  width: 0;

  transition: width 0.2s 0.2s ease-in;
  -webkit-transition: width 0.2s 0.2s ease-in;
  -moz-transition: width 0.2s 0.2s ease-in;
  -ms-transition: width 0.2s 0.2s ease-in;
  -o-transition: width 0.2s 0.2s ease-in;
}