.harmonika {
  width: 100%;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__title {
      font-family: $sans-serif;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  &__children {
    height: 0;
    overflow: hidden;
  }

  &__children--show {
    height: fit-content;
    overflow: hidden;
  }
}