.notice_banner {
  @extend .m-b-s;
  @extend .m-h-s;

  width: auto;
  background: $extra-light-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  &>div:first-child {
    @extend .m-r-xs;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  div {
    font-size: 0.75rem;
    line-height: 22px;
  }
}

@media screen and (max-width: $mobile) {
  .notice_banner {
    padding: 16px;

    div {
      font-size: 0.875rem;
    }

    &>div:first-child {
      margin-right: 16px;
    }
  }
}