.product-note-tooltip-content {
  background-color: #FFFFFF;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  max-width: 300px;
  min-width: 150px;
  padding: 1rem;

  -webkit-animation: fade-in .5s cubic-bezier(.39, .575, .565, 1.000) both;
  animation: fade-in .5s cubic-bezier(.39, .575, .565, 1.000) both;

  &__title {
    font-family: $sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #ACACAC;
    padding-bottom: 1rem;
  }

  &__text {
    font-family: $sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    text-align: left;
    white-space: pre-line;

    span {
      text-align: end;
    }
  }

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0
    }

    100% {
      opacity: 1
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0
    }

    100% {
      opacity: 1
    }
  }
}