.repeat_prescription-banner-error {
    display: flex;
    width: 100%;
    max-width: 505px;
    align-items: center;
    padding: 8px;
    background: rgba(255, 12, 12, 0.08);
    border-radius: 10px;
    margin-bottom: 24px;

    p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #FF0C0C;
        margin-left: 10px;
    }

    svg {
        align-self: center;
        margin-top: 3px !important;
        min-width: 24px;
    }
}