.repeat_prescription-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--w100 {
        width: 100%;
    }

    &__visual {
        width: 25px;
        min-width: 25px;
        height: 25px;
        border: 2px solid gray;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__visual-bg-blue {
        background-color: #02a977;
        border: none;
    }

    input {
        display: none;
    }
}