.footer_account_mobile {
  @extend .p-t-l;
  @extend .p-b-s;

  display: none;
  background: white;

  .border-top {
    border-top: 1.5px solid $light-grey;

    .p6--semi-bold {
      font-size: 0.875rem;
      color: $black;
      font-weight: 600;
    }

    .p6 {
      font-size: 0.875rem;
    }
  }

  .footer_account_mobile__group {
    @extend .p-h-s;

    text-align: left;

    &.logo {
      padding: 45px 20px 0;

      &>img {
        margin-right: 25px;
      }
    }

    .footer_account_mobile__navigation {
      .navigation__group {
        @extend .m-b-ss;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ul {
          @extend .m-t-s;
          list-style: none;

          li {
            margin-bottom: 12px;
          }

          a {
            @extend .p4;
            text-decoration: none;
            color: $off-black;

            img {
              max-width: 42px;
              vertical-align: middle;
            }
          }
        }

        p {
          @extend .p3--semi-bold;
        }

        .navigation__group__title {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .footer_gmpc {
      @extend .m-t-ss;
      color: $mid-grey;
      margin-top: 45px;

      .p6 {
        line-height: 26px;
      }

      &>img {
        @extend .m-b-xs;

        margin-bottom: 21px;
        width: 137px;
      }

      p:last-child {
        font-size: 0.75rem;
      }
    }
  }

  .footer_images {
    @extend .m-v-mm;
    color: $mid-grey;

    &>img {
      @extend .m-r-s;
      @extend .m-b-s;
    }
  }

  .footer_part_of {
    @extend .p-v-mm;

    color: $off-black;

    img {
      @extend .m-t-xs;
      @extend .m-b-s;
    }
  }

  .copy_right {
    align-self: center;
    text-align: center;
    margin-top: 32px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #000;
  }
}

@media screen and (max-width: $mobile) {
  .footer_account_mobile {
    display: block;
  }

  .footer_part_of {
    padding: 32px 20px 53px;
  }
}