// Breakpoints
$laptop: 1440px - 1px;
$tablet: 768px - 1px;
// $mobile: 375px - 1px;
$mobile: $tablet;

// Colors

//primary colors
$purple: #3e4057;
$blue: #6dbdfe;
$green: #13ae7d;
$green-light: #36bf94;
$green-lighter: #defdf3;
$green-dark: #57b799;
$orange: #ff7738;
$orange-dark: #ff5405;
$yellow: #ffca3e;
$yellow-light: #fbf8ef;
$cream: #f3f1e2;
$peach: #c7826a;
$peach-dark: #944f37;
$peach-medium: #B96345;
$pink: #ff8791;
$black: #2b2b2b;
$beige : #ffefe2;

//secondary colors
$success: #45b643;
$error: #ff0c0c;
//waiting for designer

//functional colors
$black: #000000;
$off-black: #1e1e1e;
$dark-grey: #484848;
$mid-grey: #787878;
$light-grey: #d5d5d5;
$extra-light-grey: #f6f6f6;

$tertiary: #0066ff;
$background-color: $extra-light-grey;

// Typography
$serif: "Frank Ruhl Libre", serif;
$sans-serif: "Poppins", sans-serif;
$body-font-size: 16px;

// Spacing
$space-xxs: 5px;
$space-xs: 10px;
$space-s: 20px;
$space-ss: 30px;
$space-m: 40px;
$space-mm: 50px;
$space-l: 60px;
$space-xl: 80px;
$space-xxl: 100px;
$space-xxxl: 120px;
$space-xxxxl: 140px;
$space-xxxxxl: 160px;
$space-xxxxxxl: 180px;
$space-superxl: 390px;

$header-height: 100px;

// Transitions
$form-transition-duration: 0.2s;

// Border radius
$border-radius-s: 3px;
$border-radius-m: 5px;
$border-radius-l: 10px;

// Table
$table-row-height: 50px;
$border-color: $light-grey;