.viewPerscriptionPopup {
    .verification_card {
        min-width: 30% !important;

        &__content {
            margin-top: 0px;
        }
    }

    &__content {
        width: 100%;
        display: flex;
        justify-content: center;

        &__header {
            display: flex;
            justify-content: space-around;
            width: 95%;

            &__title {
                font-family: "Poppins", sans-serif;
                font-size: 25px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #000000;
                width: 100%;
                margin: 20px;
            }

            &__close {
                cursor: pointer;
            }
        }

        .verification_card__content__image_container {

            width: 95%;
            min-height: 500px;
            max-height: 85vh;
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
                max-height: 75vh;
                align-items: center;
                width: 100%;
                object-fit: contain;
            }

            iframe {
                max-height: 75vh;
                min-height: 50vh;
                width: 100%;
                flex-grow: 1;
                display: block;
            }
        }

        .verification_card__Prescriptionbuttons {
            text-align: center;
            margin: 20px;
            height: 2.5rem;
            align-self: center;

            &>div {
                @extend .w-240;
                @extend .h-40;
                @extend .m-b-s;
            }
        }
    }
}