// loading icon animation
.wrapper {
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
}

.wrapper .behind {
  position: absolute;
  top: 19px;
  left: 19px;
  width: 42px;
  height: 42px;
  background-color: #566d7c;
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  animation-timing-function: linear;
}

.wrapper svg {
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes loading {
  0% {
    height: 42px;
  }

  50% {
    height: 0px;
  }

  100% {
    height: 42px;
  }
}