.loading_card {
  text-align: center;
  z-index: 3;

  .loading_card__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading_card__title {
      .h6-a--serif {
        @extend .m-b-s;
      }
    }

    .loading_card__image {
      @extend .m-b-s;
    }
  }
}

@media screen and (max-width: $mobile) {
  .loading_card {
    width: 100%;

    .loading_card__content {
      justify-content: center;
      height: 100vh;
    }
  }
}