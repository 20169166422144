.product_preview_content {
  padding-bottom: 1rem;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 12px;
    font-family: $sans-serif;
    font-size: 22px;
    font-weight: 500;
    position: sticky;
    top: 0px;
    background: #f6f6f6;
    z-index: 10;
  }

  &__headerRow {
    justify-content: left;
    font-family: $sans-serif;
    font-size: 1.5em;
    font-weight: 500;
    position: sticky;
    background: white;
    word-break: break-word;
    padding-right: 24px;

    height: 82px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 24px;

    flex: none;
    order: 0;
    flex-grow: 0;

    &__right {
      flex: none;
      order: 2;
      flex-grow: 1;
      font-size: 21px;
      line-height: 24px;

      text-align: right;
      letter-spacing: 1px;
      color: #787878;
    }
  }

  &__informations {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &__gridContainer {
      background-color: #F6F6F6;
      margin-bottom: 12px;
      box-shadow: 0px 6px 19px -6px rgba(0, 0, 0, 0.05);
      max-height: 65vh;

      display: grid;
      grid-template-columns: 2fr repeat(2, 1fr);
      grid-template-rows: 3fr 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      &__gridElementHeader {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 24px;
        gap: 8px;

        background: #FFFFFF;
        border-bottom: 1px solid #D5D5D5;

        font-family: $sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 2rem;

        color: #484848;
        flex: none;
        order: 0;
        flex-grow: 0;

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      &__gridElementContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        gap: 24px;
      }

      .div1 {
        overflow: auto;
        align-content: center;
        padding: 16px;
        margin: 8px;
        background-color: white;
        grid-area: 1 / 1 / 2 / 2;
        max-height: 60vh;
        max-width: 50vw;

        .spinner_loader {
          height: inherit;
          overflow: hidden;
          display: flex;
          justify-content: center;
        }

        .noPhotos {
          background-color: #eaeaea;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: 16px;

          font-family: $sans-serif;
          color: #484848;
          text-align: center;
          font-size: 16px;

          svg {
            margin: 5px;
            width: 70px;
            height: 70px;
          }
        }
      }

      .div2 {
        margin: 8px;
        background-color: white;
        overflow: auto;
        grid-area: 1 / 2 / 2 / 3;
      }

      .div3 {
        margin: 8px;
        background-color: white;
        overflow: auto;
        grid-area: 1 / 3 / 3 / 4;

        .noTerpenes {
          font-family: $sans-serif;
          color: #787878;
          margin: auto;
          padding: 8px 0px;
          text-align: center;
          vertical-align: middle;
          font-size: 16px;
          line-height: 141%;
          font-weight: 400;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: 16px;
        }
      }

      .div4 {
        margin: 8px;
        background-color: white;
        overflow: auto;
        grid-area: 2 / 1 / 3 / 3;

        .spinner_loader {
          margin: auto;
          height: 50px;
          overflow: hidden;
          display: flex;
          justify-content: center;
        }

        .noDocs {
          color: #787878;
          margin: auto;
          padding: 8px 0px;
          text-align: center;
          vertical-align: middle;
          font-size: 16px;
          line-height: 141%;
          font-weight: 400;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: 16px;
        }
      }
    }
  }

  .pieChartContainer {
    max-width: 90%;
    margin-bottom: 10px;
  }

  .barChartContainer {
    max-width: 90%;
  }

  .previewTerpene {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    align-self: flex-start;
    width: 95%;
    gap: 12px;

    &__item {
      box-sizing: border-box;
      padding-bottom: 6px;

      display: block;
      border-bottom: 1px solid #D5D5D5;

      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      font-family: $sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.85rem;
      line-height: 153.5%;
    }

    &__value {
      word-spacing: 8px;
      display: contents;
      font-style: normal;
      font-weight: 400;
      font-size: 0.85rem;
      line-height: 153.5%;

      color: #787878;
    }
  }

  .productPreviewDetailsRow {
    font-family: $sans-serif;
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    align-self: stretch;
    color: #ACACAC;
    font-size: 1rem;
    font-weight: 600;

    &__title {
      align-items: flex-start;
    }

    &__text {
      min-width: 1.5rem;
      color: #1E1E1E;
      font-size: 1rem;
      font-weight: 300;
      line-height: 153.5%;
    }

    &__document {
      cursor: pointer;
      max-width: 33%;

      display: flex;
      padding: 8px 16px;
      align-items: center;
      gap: 0.5rem;
      border-radius: 4px;
      border: 1px solid #D5D5D5;

      a:link {
        text-decoration: none;
      }

      a:visited {
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      a:active {
        text-decoration: none;
      }

      a {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #3B5EDB;
        font-size: 0.85rem;
        font-family: $sans-serif;
        font-weight: 400;
      }
    }
  }
}

.image-gallery {
  margin: 0;
}

.image-gallery-slide img {
  width: auto;
  height: 430px;
  max-height: 80vh;
  object-fit: scale-down;
  overflow: hidden;
  object-position: center center;
}

.image-gallery-bullets {
  padding-bottom: 6px;
  bottom: -32px !important;

  .image-gallery-bullet {
    padding: 8px !important;
    border: 1px solid #cecece !important;
    margin: 0px 10px !important;

    &:hover {
      background: #b2d6bf !important;
      border: 1px solid #b2d6bf !important;
    }

    &:focus {
      background: #b2d6bf !important;
      border: 1px solid #b2d6bf !important;
    }

    &.active {
      background-color: #13ae7d !important;
    }

    &.active:hover {
      background: #b2d6bf !important;
    }
  }
}

.image-gallery-icon {
  padding: 50px 0px !important;
}

.image-gallery-icon:hover {
  color: #7ab08c !important;
}

@media screen and (max-width: $mobile) {
  .product_preview_content {
    padding-bottom: 1rem;
    padding-left: 6px;
    padding-right: 6px;
    display: block;
    max-height: fit-content;

    &__header {
      padding-top: 1rem;
      padding-bottom: 6px;
      font-size: 2rem;
      font-weight: 500;
      background: #f6f6f6;
    }

    &__headerRow {
      font-size: 0.75rem;

      height: 40px;
      padding: 0px;
      padding-left: 12px;
      gap: 12px;

      &__right {
        font-size: 0.65rem;
        line-height: 0.65rem;
      }
    }

    &__informations {
      display: block;
      padding-top: 12px;

      max-height: fit-content;

      &__gridContainer {
        display: block;

        max-height: fit-content;

        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        &__gridElementHeader {
          padding: 6px 12px;
          gap: 4px;

          font-size: 0.75rem;
          line-height: 1rem;
        }

        &__gridElementContent {
          padding: 12px;
          gap: 12px;
        }

        .div1 {
          overflow: scroll;
          max-height: 50vh;
          padding: 4px;
          height: fit-content;
          margin: 4px;
          margin-bottom: 12px;
        }

        .div2 {
          margin: 4px;
          height: fit-content;
          margin-bottom: 12px;
        }

        .div3 {
          margin: 4px;
          height: fit-content;
          margin-bottom: 12px;

          .noTerpenes {
            font-size: 0.625rem;
          }
        }

        .div4 {
          margin: 4px;
          height: fit-content;
          margin-bottom: 12px;
        }

        .div5 {
          margin: 4px;
          height: fit-content;
          min-height: 15vh;

          .noDocs {
            font-size: 0.625rem;
            gap: 8px;
          }
        }
      }
    }

    .previewTerpene {
      gap: 6px;

      &__item {
        font-size: 0.625rem;
      }

      &__value {
        font-size: 0.55rem;
      }
    }

    .productPreviewDetailsRow {
      font-size: 0.625rem;
      gap: 6px;

      &__text {
        font-size: 0.625rem;
      }

      &__document {
        max-width: 66%;
        padding: 4px 8px;
        gap: 6px;

        a {
          font-size: 0.55rem;
        }
      }
    }

    .pieChartContainer {
      width: 66%;
    }

    .barChartContainer {
      width: 66%;
    }
  }
}