$sizes: (
  "xxs": $space-xxs,
  "xs": $space-xs,
  "s": $space-s,
  "ss": $space-ss,
  "m": $space-m,
  "mm": $space-mm,
  "l": $space-l,
  "xl": $space-xl,
  "xxl": $space-xxl,
  "xxxl": $space-xxxl,
  "xxxxl": $space-xxxxl,
  "xxxxxl": $space-xxxxxl,
  "xxxxxxl": $space-xxxxxxl,
  "superxl": $space-superxl,
);

// Padding
@each $key, $value in $sizes {

  // All
  .p-#{$key} {
    padding: $value;
  }

  // Vertical axis
  .p-v-#{$key} {
    padding-top: $value;
    padding-bottom: $value;
  }

  // Horizontal axis
  .p-h-#{$key} {
    padding-left: $value;
    padding-right: $value;
  }

  // Top
  .p-t-#{$key} {
    padding-top: $value;
  }

  // Bottom
  .p-b-#{$key} {
    padding-bottom: $value;
  }

  // Left
  .p-l-#{$key} {
    padding-left: $value;
  }

  // Right
  .p-r-#{$key} {
    padding-right: $value;
  }
}

// Horizontal axis - padding:auto
.p-h-auto {
  padding-left: auto;
  padding-right: auto;
}

// Vertical axis - padding:auto

.p-v-auto {
  padding-top: auto;
  padding-bottom: auto;
}

// Margin
@each $key, $value in $sizes {

  // All
  .m-#{$key} {
    margin: $value;
  }

  // Vertical axis
  .m-v-#{$key} {
    margin-top: $value;
    margin-bottom: $value;
  }

  // Horizontal axis
  .m-h-#{$key} {
    margin-left: $value;
    margin-right: $value;
  }

  // Top
  .m-t-#{$key} {
    margin-top: $value;
  }

  // Bottom
  .m-b-#{$key} {
    margin-bottom: $value;
  }

  // Left
  .m-l-#{$key} {
    margin-left: $value;
  }

  // Right
  .m-r-#{$key} {
    margin-right: $value;
  }
}

// Horizontal axis - margin:auto
.m-h-auto {
  margin-left: auto;
  margin-right: auto;
}

// Vertical axis - margin:auto

.m-v-auto {
  margin-top: auto;
  margin-bottom: auto;
}

// Vertical and horizontal axis - flex center

.flex-center {
  height: calc(100vh - 96px);
  display: flex;
  justify-content: center;
  align-items: center;
}

// Width
$widths: (
  "220": 220px,
  "240": 240px,
  "252": 252px,
);

// Height
$heights: (
  "40": 40px,
  "48": 48px,
  "56": 56px,
  "64": 64px,
);

@each $key, $value in $widths {

  // All
  .w-#{$key} {
    width: $value;
  }
}

@each $key, $value in $heights {

  // All
  .h-#{$key} {
    height: $value;
  }
}

$widths: (
  "5": 5%,
  "10": 10%,
  "15": 15%,
  "20": 20%,
  "25": 25%,
  "30": 30%,
  "35": 35%,
  "40": 40%,
  "45": 45%,
  "50": 50%,
  "55": 55%,
  "60": 60%,
  "65": 65%,
  "70": 70%,
  "75": 75%,
  "80": 80%,
  "85": 85%,
  "90": 90%,
  "95": 95%,
  "100": 100%,
);

// Width
@each $key, $value in $widths {

  // All
  .w--#{$key} {
    width: $value;
  }
}

@media screen and (max-width: $mobile) {
  .h-48 {
    height: 56px;
  }

  .h-40 {
    height: 56px;
  }

  .w-252 {
    height: 64px;
  }
}

.d-flex {
  display: flex
}

.j-content-between {
  justify-content: space-between;
}

$grid__cols: 12;

@for $i from 1 through $grid__cols {
  .col-#{$i} {
    width: (100 / ($grid__cols / $i) - 0.5) * 1%;

    //&:not(.block) {
    //  display: flex;
    //  align-items: center;
    //}
  }
}