.checkbox-container {
  .checkbox {
    position: relative;
    display: flex;
    margin-bottom: 20px;
    cursor: pointer;
    align-items: center;

    .checkbox-text {
      font-weight: 600;
      font-size: 0.86rem;
    }

    .inner-label {
      position: relative;
      display: inline-block;
      background-color: white;
      width: 40px; // Outer size
      height: 40px; // Outer size
      border: 2px solid $off-black;
      border-radius: 50%;
      -webkit-tap-highlight-color: transparent;
      margin-right: 20px;

      &:after {
        content: "";
        position: absolute;
        top: 4px; // Inner offset
        left: 4px; // Inner offset
        width: 28px; // Inner size
        height: 28px; // Inner size
        border-radius: 100%;
        background: $off-black;
        transform: scale(0);
        transition: all 0.2s ease;
        opacity: 0.08;
        pointer-events: none;
      }
    }

    &:hover {
      .inner-label:after {
        transform: scale(2);
      }
    }
  }

  input[type="checkbox"]:focus+.inner-label:after {
    transform: scale(2);
  }

  input[type="checkbox"]:checked+.inner-label {
    border-color: $off-black;

    &:after {
      transform: scale(1);
      transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
      opacity: 1;
    }
  }
}