.textarea-container {
  width: 100%;
  position: relative;
  display: inline-block;
  padding-top: 16px;

  transition: border-bottom 0 $form-transition-duration ease-out;

  .label-top {
    font-size: 9px;
    top: 10%;
  }

  .label-disabled {
    color: $light-grey;
  }

  label {
    @extend .label--semi-bold;

    display: block;
    position: absolute;
    top: 17%;
    left: $space-xs;
    color: $mid-grey;
    transition: 0.3s;
    pointer-events: none;

    &:hover {
      cursor: text;
    }
  }

  textarea {
    @extend .p-l-xs;
    @extend .p-t-s;
    @extend .p-b-xs;

    font-family: $sans-serif;
    font-weight: 500;
    resize: none;
    min-height: 200px;
    max-height: 600px;

    vertical-align: top;
    width: 100%;
    border: none;
    background: $extra-light-grey;
    border-bottom: 2px solid $mid-grey;
    transition: 0.3s;
    outline: none;
    z-index: 99;

    &::placeholder {
      color: $mid-grey;
    }

    &:focus~label {
      font-size: 9px;
      top: 10%;
    }

    &:disabled {
      border-bottom: 1px solid $light-grey;
    }

    &:focus~.animated-border {
      width: 100%;
    }

    &:focus {
      background: #e8e8e8;
      border-bottom: 2px solid transparent;
    }
  }
}

.textarea-container:focus-within {
  border-bottom: none;
}

.animated-border--show {
  width: 100%;
}