.repeat_prescription-banner-info {
    display: flex;
    width: 100%;
    max-width: 505px;
    align-items: center;
    padding: 8px;
    background: rgba(213, 213, 213, 0.22);
    border-radius: 10px;
    margin-bottom: 24px;

    p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #484848;
        margin-left: 13px;
    }

    svg {
        align-self: center;
        margin-top: 3px !important;
        min-width: 24px;
    }
}