.changing_password {
  @extend .flex-center;
  height: fit-content;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &__content {
    @extend .p-h-s;
    @extend .p-t-l;

    width: 100%;
    max-width: 500px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    @extend .m-b-ss;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      @extend .m-t-s;
      @extend .m-b-ss;
    }

    .p5 {
      width: 90%;

      color: $off-black;
    }
  }

  &__form {
    @extend .m-b-m;

    width: 100%;
  }

  &__button {
    width: 100%;
    @extend .h-48;
    @extend .m-b-m;
  }
}

@media screen and (max-width: $mobile) {
  .changing_password {
    margin-top: 1rem;
    align-items: flex-start;
    height: auto;

    &__content {
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;
    }

    &__title {
      align-items: flex-start;
      text-align: left;
      width: 100%;

      h1 {
        font-weight: bold;
        font-family: $serif;
        font-size: 2.25rem;
      }
    }

    &__form {
      margin-bottom: $space-mm;
      width: 100%;
    }

    &__button {
      width: 100%;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}