.mobile_order__header {
  @extend .p-h-s;
  @extend .p-v-m;

  display: none;
  line-height: 26px;
  background: white;
  border-bottom: 1px solid $light-grey;
  padding: 0 0 40px;
  position: relative;

  &__report {
    position: absolute;
    top: 10px;
    right: 50px;
  }

  &__top {
    @extend .m-b-s;

    padding: 24px 20px 30px;
    background-color: $green-light;

    h5 {
      line-height: 46px;
      margin-bottom: 20px;
      font-size: 36px;
    }
  }

  &__details {
    letter-spacing: 0.1em;
    text-transform: uppercase;

    &__left {
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  &__text {
    @extend .m-b-m;
    @extend .m-t-m;

    width: 58%;
    padding: 0 20px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__left {
      display: flex;

      &>div {
        @extend .m-r-s;
      }
    }

    &__right {
      display: flex;
      align-items: center;
    }
  }

  &__top__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;

    .p6 {
      font-weight: 500;
    }
  }

  &__icon {
    border-radius: 50%;
    background-color: #00a372;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tracking_button {
  display: none;
}

@media screen and (max-width: $mobile) {
  .mobile_order__header {
    display: block;

    &__top {
      &>p {
        margin-bottom: $space-xs;
      }
    }

    &__text {
      width: 100%;
    }

    &__buttons {
      padding: 0 20px;

      &__left {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        margin-bottom: $space-s;

        &>div {
          margin-bottom: $space-xs;
          width: 100%;
        }
      }

      &__right {
        width: 100%;
        justify-content: center;
      }
    }
  }

  .tracking_button {
    display: block;
  }
}