.repeat_prescription--header {
  width: 100%;
  height: 96px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  border-bottom: 4px solid #F6F6F6;
  background-color: #fff;

  .xIcon {
    display: flex;
    width: 74px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    cursor: pointer;

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      text-align: right;
    }
  }
}

@media (max-width: $mobile) {
  .repeat_prescription--header {
    .xIcon {
      width: unset;

      p {
        display: none;
      }
    }
  }
}