.generic_popup {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .verification_card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3rem;
        padding-bottom: 2rem;
    }

    &__title {
        font-family: $sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        text-align: center;
    }

    &__text {
        font-family: $sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        padding: 1rem;
    }

    &__buttons {
        margin: auto;
        width: 80%;
        margin-bottom: 2rem;

        button {
            height: 48px;
        }
    }

    a {
        text-decoration: none;
    }
}