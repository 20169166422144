.clinics_bottom_bar_links {
  height: 100%;
  display: flex;

  &__link {
    font-family: $sans-serif;
    font-size: 14px;

    line-height: 26px;
    color: #787878;
    cursor: pointer;
    font-weight: 500;
    height: 100%;

    &__text {
      padding: 1rem 1.5rem 0 1.5rem;
      user-select: none;

      &__count {
        color: white;
        display: inline-flex;

        min-width: 26px;
        max-height: 26px;
        border-radius: 50%;
        justify-content: center;
        background-color: $green-light;

        &__zero {
          background-color: #787878;
        }
      }
    }

    &:hover {
      color: $green;
      background-color: $extra-light-grey;
      transition: 0.2s;
    }

    &--active {
      color: $green;
      border-bottom: 1px solid $green;
    }
  }
}