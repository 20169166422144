.check_your_email {
  @extend .m-t-xl;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-m;

    width: 60%;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &>p {
      @extend .m-b-s;
    }

    &>h1 {
      @extend .m-b-m;
    }

    &>p.p5 {
      text-align: left;
      width: 75%;
    }
  }

  &__cards {
    @extend .m-b-m;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__title {
      @extend .m-b-m;

      text-align: center;
    }

    &__text {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top: 1px solid gray;
    width: 100%;
    padding: 3rem;
    padding-top: 2rem;
    text-align: center;
    font-family: $sans-serif;

    &__question {

      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }
  }

  &__cards--mobile {
    display: none;
  }

  &__buttons {
    @extend .m-b-l;

    display: flex;
    justify-content: center;

    &>div {
      @extend .w-240;
      @extend .h-48;
      @extend .p-r-s;
    }
  }
}

@media screen and (max-width: $tablet) {
  .check_your_email {
    &__title {
      width: 90%;

      &>p {
        width: 100%;
      }
    }

    &__buttons {
      width: 100%;
      flex-direction: column;

      &>div {
        margin-bottom: $space-s;
        padding-right: 0px;
        width: 100%;
      }
    }

    &__cards {
      display: none;
    }

    &__cards--mobile {
      margin-bottom: $space-xl;
      width: 100%;
      display: block;
    }
  }
}

@media screen and (max-width: $mobile) {
  .check_your_email {
    margin-top: $space-l;

    &__content {
      width: 100%;
      align-items: flex-start;
      padding-left: $space-s;
      padding-right: $space-s;
    }

    &__title {
      align-items: flex-start;
      text-align: left;
      width: 100%;

      &>h1 {
        font-family: $serif;
        font-weight: bold;
        font-size: 2.25rem;
      }

      &>p {
        width: 100%;
      }
    }

    &__cards {
      display: none;
    }

    &__cards--mobile {
      display: block;
      margin-bottom: $space-m;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}