.reusable_modal {
  background: white;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  min-width: 50%;
  font-family: $sans-serif;

  &--w80 {
    width: 80%;
  }

  &__header {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;

    font-family: $sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #000000;
    border-bottom: 1px solid gray;

    &__close {
      cursor: pointer;
    }
  }

  &__content {
    @extend .m-b-s;
    display: flex;
    height: fit-content;
    width: 100%;

  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem;
    border-top: 1px solid gray;
  }
}