.one_last_step {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  margin-top: 88px;
  display: flex;
  justify-content: center;

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-s;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &>p {
      @extend .m-b-s;
    }

    &>h1 {
      @extend .m-b-m;
    }
  }

  &__form {
    @extend .m-b-m;

    width: 60%;
  }

  &__terms {
    @extend .m-b-mm;
  }

  &__accept_terms {
    @extend .m-b-m;
  }

  &__button {
    @extend .m-b-m;
    @extend .w-240;
    @extend .h-48;
  }
}

@media screen and (max-width: $mobile) {
  .one_last_step {
    margin-top: $space-l;

    &__content {
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;
    }

    &__title {
      margin-bottom: $space-ss;
      align-items: flex-start;
      text-align: left;

      &>h1 {
        font-family: $serif;
        font-weight: bold;
        font-size: 2.25rem;
      }
    }

    &__form {
      width: 100%;
    }

    &__terms {
      width: 100%;
    }

    &__accept_terms {
      margin-bottom: $space-ss;
      width: 100%;
      text-align: left;
    }

    &__button {
      width: 100%;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}