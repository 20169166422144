.form-row {
  @extend .m-b-s;
  @extend .p-t-s;

  display: flex;
  justify-content: space-between;
  width: 100%;

  &.no-padding-top {
    padding-top: 0;
  }
}