.clinic_filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    animation: fade-in 0.33s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    background-color: #f7f7f7;
    border: 2px solid #cccccc;

    cursor: pointer;
    font-family: 'Poppins';
    min-width: 110px;
    width: auto;
    height: 48px;
    position: relative;

    border-radius: 5px;

    /*Disable text selection (so the div acts as a button)*/
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */

    /* custom scrollbar */
    ::-webkit-scrollbar {
        width: 20px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $light-grey;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: $mid-grey;
    }

    &:hover {
        transition: 0.3s;
        background: $orange-dark;
        color: white;
        border-color: $orange-dark;

        path {
            transition: 0.3s;
            fill: white;
        }
    }

    &__content {
        width: 100%;
    }

    &__text {
        display: flex;
        align-items: center;
        min-width: max-content;

        p {
            @extend .p-l-xs;
            font-weight: 600;
        }
    }

    &__options {
        font-family: 'Poppins';
        animation: swing-in-top-fwd .5s cubic-bezier(.175, .885, .32, 1.275) both;

        box-shadow: 0px 5px 12px -6px rgba(0, 0, 0, 1);
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        width: 316px;
        position: absolute;
        background: white;
        top: 46px;
        left: -2px;
        max-height: 600px;
        overflow-wrap: break-word;
        overflow-y: auto;
        overflow-x: hidden;
        color: $off-black;

        cursor: default;

        &__section_title {
            cursor: default;

            padding: 1rem 1rem 0.25rem;
            color: rgba(120, 120, 120, 1);
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;

            hr {
                margin: 6px 0px 6px;
                border: none;
                height: 1px;
                background-color: lightgray;
            }
        }

        &__option {
            position: relative;
            left: 4px;
            border-radius: 0.5rem;
            margin: 2px;
            padding: 0.5rem;
            font-size: 14px;
            max-width: 318px;
            line-height: 26px;
        }

        &__checkbox {
            margin: 5px;
            display: inline;
            vertical-align: middle;
            transform: scale(1.5);

            &__text {
                margin: 10px;
                font-size: 14px;
            }
        }
    }

    &__tabs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-self: center;
        padding: 8px;
        gap: 12px;

        height: 42px;
        border-radius: 50px;

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;

        &__tab {

            animation: swing-in-left-fwd .5s cubic-bezier(.175, .885, .32, 1.275) both;
            margin-right: 1rem;
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-self: center;
            padding: 8px 24px;
            gap: 12px;

            height: 42px;

            background: #6883E3;
            border-radius: 50px;
            gap: 12px;
            width: auto;

            font-family: 'Poppins';
            color: white;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;

            background: #6883E3;
            border-radius: 50px;

            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;

            &__icon {
                width: 14px;
                height: 14px;

                fill: none;
                cursor: pointer;

                border-radius: 50%;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    /* ----------------------------------------------
   * Generated by Animista on 2022-6-22 11:4:17
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

    @keyframes swing-in-top-fwd {
        0% {
            transform: rotateX(-100deg);
            transform-origin: top;
            opacity: 0
        }

        100% {
            transform: rotateX(0deg);
            transform-origin: top;
            opacity: 1
        }
    }

    /* ----------------------------------------------
 * Generated by Animista on 2022-7-9 18:6:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    @keyframes swing-in-left-fwd {
        0% {
            transform: rotateY(100deg);
            transform-origin: left;
            opacity: 0
        }

        100% {
            transform: rotateY(0);
            transform-origin: left;
            opacity: 1
        }
    }

    /* ----------------------------------------------
 * Generated by Animista on 2023-9-14 14:22:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    /**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
    @keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}