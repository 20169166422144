.order_page {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  border-radius: 4px;
  overflow: hidden;
}

.orders_loading--spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: $mobile) {
  .order_page {
    &_no_prescription {
      &>div {
        &>div {
          &>div {
            width: 100%;
          }
        }
      }
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}