.side_preview {
  width: 0px;
  overflow: hidden;
  background: #FFFFFF;
  transition: width 0.1s linear;
  overflow-y: scroll;
  /*  max-height:800px;*/
  position: sticky;

  &--show {
    width: 500px;
    animation: slidein 0.3s linear;
  }
}

@keyframes slidein {
  0% {
    transform: translate(200%, 0%);
    width: 0px;
  }

  100% {
    transform: translate(0%, 0%);
    width: 500px;
  }
}