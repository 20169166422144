.previous__order__header {
  @extend .p-v-m;
  @extend .p-h-s;

  // border-bottom: 1px solid $light-grey;
  background: $green-light;

  .number_of_items--mobile {
    display: none;
  }

  .number_of_items--desktop {
    display: block;
  }

  &__top {
    display: flex;

    &>div {
      &>h2 {
        @extend .m-b-xs;
      }
    }

    &>p {
      margin-left: auto;
    }
  }

  &__icon {
    border-radius: 50%;
    background-color: #00a372;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 22px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: white;
    border-bottom: 1px solid $light-grey;

    &>div {
      display: flex;
      align-items: center;

      &>button:first-child {
        @extend .m-b-xs;
      }
    }

    .left {
      @extend .m-r-xs;
    }
  }

  &__details--mobile {
    display: none;
  }

  &__details--desktop {
    display: block;
  }
}

@media screen and (max-width: $mobile) {
  .previous__order__header {
    &__top {
      flex-direction: column;
      text-align: left;

      &>div {
        margin-bottom: 32px;
      }

      &>p {
        margin-left: 0;
        margin-right: auto;
        margin-bottom: $space-s;
      }
    }

    .number_of_items--mobile {
      display: block;
    }

    .number_of_items--desktop {
      display: none;
    }

    &__buttons {
      flex-direction: column;

      &>div {
        width: 100%;
        flex-direction: column;
        margin-bottom: $space-xs;

        &>div {
          margin-bottom: $space-s;

          width: 100%;
        }
      }
    }

    &__details--mobile {
      display: block;
    }

    &__details--desktop {
      display: none;
    }
  }
}