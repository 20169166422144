.name {
  @extend .m-t-xxl;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  display: flex;
  justify-content: center;

  a {
    color: $off-black;
    font-weight: 600;
  }

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 570px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-m;

    flex-direction: column;
    text-align: center;
    width: 85%;

    &>p.label--caps--normal {
      @extend .p-b-s;
    }

    &>p.p5 {
      @extend .p-t-m;

      text-align: left;
    }
  }

  &__attention {
    @extend .m-b-mm;
    @extend .p-s;

    background-color: $extra-light-grey;
  }

  &__red_button {
    @extend .m-b-s;

    display: flex;
    align-items: center;

    p {
      @extend .p-l-xs;
    }
  }

  &__text {
    color: $off-black;
  }

  &__form {
    @extend .m-b-m;

    width: 60%;
  }

  &__doctor_name_form {
    @extend .m-b-m;

    width: 75%;
  }

  &__button {
    @extend .w-240;
    @extend .h-48;
    @extend .m-b-mm;
  }

  &__email {
    @extend .m-b-m;

    display: flex;
    justify-content: center;

    text-align: center;
    width: 75%;
  }

  &__social {
    text-align: center;

    &>div {
      @extend .m-t-s;
      @extend .m-h-auto;

      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }
}

@media screen and (max-width: $mobile) {
  .name {
    margin-top: $space-l;

    &__content {
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;
      justify-content: flex-start;
    }

    &__title {
      align-items: flex-start;
      text-align: left;
      width: 100%;

      h1 {
        font-family: $serif;
        font-weight: bold;
        font-size: 2.25rem;
      }
    }

    &__form {
      width: 100%;
    }

    &__doctor_name_form {
      width: 100%;
    }

    &__button {
      margin-bottom: $space-m;
      width: 100%;
    }

    &__buttons {
      width: 100%;
      flex-direction: column;

      &>div {
        width: 100%;
      }
    }

    &__email {
      margin-bottom: $space-m;
      width: 100%;
      text-align: left;

      p {
        font-size: 1rem;
      }

      div {
        width: 90%;
      }
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}