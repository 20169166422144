.radio-container {
  width: auto;

  .radio {
    position: relative;
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;

    .option-text {
      // I've added this because FadeIn was
      // making it display weird
      // width: auto;
      width: calc(100% - 44px);
      font-weight: 400;
      font-size: 0.875rem;
      color: $off-black !important;
      font-family: $sans-serif;
    }

    .inner-label {
      position: relative;
      display: inline-block;
      background-color: white;
      width: 24px; // Outer size
      height: 24px; // Outer size
      border: 1px solid $mid-grey;
      border-radius: 50%;
      -webkit-tap-highlight-color: transparent;
      margin-right: 20px;

      &:after {
        content: "";
        position: absolute;
        top: 5px; // Inner offset
        left: 5px; // Inner offset
        width: 12px; // Inner size
        height: 12px; // Inner size
        border-radius: 100%;
        background: $blue;
        transform: scale(0);
        transition: all 0.2s ease;
        opacity: 0.08;
        pointer-events: none;
      }
    }

    &:hover {
      .inner-label:after {
        transform: scale(2);
      }
    }
  }

  input:disabled~.option-text {
    color: $light-grey !important;
  }

  input[type="radio"]:focus+.inner-label:after {
    transform: scale(2);
  }

  input[type="radio"]:checked+.inner-label {
    border-color: $blue;

    &:after {
      transform: scale(1);
      transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
      opacity: 1;
    }
  }
}

@media screen and (max-width: $tablet) {
  .radio-container {
    .radio {
      align-items: flex-start;
    }
  }
}