.coloured_progress_bar {
  height: 6px;
  width: 100%;
  border-radius: 8.5px;
  background: white;

  &--orange {
    .coloured_progress_bar__progress {
      background: orange;

    }
  }

  &--gray {
    .coloured_progress_bar__progress {
      background: gray;

    }
  }

  &--green {
    .coloured_progress_bar__progress {
      background: #13ae7d;
    }
  }

  &--red {
    .coloured_progress_bar__progress {
      background: #FF0C0C;
    }
  }

  &--blue {
    .coloured_progress_bar__progress {
      background: #5271E0;
    }
  }

  &__progress {
    height: 6px;
    border-radius: 8.5px;
  }

  &--10 {
    .coloured_progress_bar__progress {
      @extend .w--10;
    }
  }

  &--20 {
    .coloured_progress_bar__progress {
      @extend .w--20;
    }
  }

  &--30 {
    .coloured_progress_bar__progress {
      @extend .w--30;
    }
  }

  &--40 {
    .coloured_progress_bar__progress {
      @extend .w--40;
    }
  }

  &--50 {
    .coloured_progress_bar__progress {
      @extend .w--50;
    }
  }

  &--60 {
    .coloured_progress_bar__progress {
      @extend .w--60;
    }
  }

  &--70 {
    .coloured_progress_bar__progress {
      @extend .w--70;
    }
  }

  &--80 {
    .coloured_progress_bar__progress {
      @extend .w--80;
    }
  }

  &--90 {
    .coloured_progress_bar__progress {
      @extend .w--90;
    }
  }

  &--100 {
    .coloured_progress_bar__progress {
      @extend .w--100;
    }
  }
}