.prescription_card {
  @extend .m-b-mm;

  &__title {
    @extend .m-b-ss;
    @extend .p-b-s;

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light-grey;
  }

  &__body {
    display: flex;

    &>img {
      @extend .m-r-s;

      width: 60px;
      height: 78px;
      flex: 1;

      object-fit: contain;
    }

    &>div {
      flex: 3;
    }

    .label--caps--normal {
      color: $dark-grey;
    }

    .p5--normal {
      @extend .p-v-xs;
    }
  }
}