.shadow {
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}

.card {
  @extend .shadow;

  width: 100%;
  background-color: white;
  border-radius: $border-radius-l;
  overflow: hidden;

  .card {
    border: 1px solid #efefef;
  }
}

.heading-filter-container {
  @extend .p-h-s;
  @extend .p-v-s;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .table-heading {
    font-size: 0.82rem;

    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;

    background-color: $light-grey;
    color: white;
    padding: 0 $space-s;
    height: 42px;
    line-height: 42px;
    border-radius: 21px;
  }

  .filter-container {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
}

table {
  border-collapse: collapse;
  font-family: $sans-serif;

  tr:not(.table-header):not(.table-body) {
    border-bottom: 1px solid $border-color;

    th,
    td {
      display: inline-block;
      //   height: $table-row-height;
      line-height: $table-row-height;
      padding: 0 10px;
      text-overflow: ellipsis;
      overflow: hidden;

      &.right {
        text-align: right;
      }
    }
  }

  tr:not(.table-header):not(.table-body):last-child {
    border-bottom: none !important;
  }

  .table-header {
    font-size: 0.7rem;
    text-transform: uppercase;

    &>div {
      padding: 0 $space-s 0 $space-xs;
    }

    th {
      text-align: left;
      color: $off-black;
    }
  }

  .table-body {

    a:not(.badge):not(.btn),
    td,
    span:not(.colored) {
      font-size: 0.7rem;
    }

    td {
      * {
        vertical-align: middle;
      }

      .table-cell-tooltip {
        @extend .card;
        @extend .p-h-xs;
        @extend .p-b-s;

        opacity: 0;
        position: fixed;
        right: 0;
        bottom: 30px;
        width: 200px;
        pointer-events: none;
        -webkit-transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
        -ms-transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;

        div {
          span {
            @extend .p-h-xs;
            @extend .m-b-xs;

            line-height: 24px;
            height: 24px;
            line-height: 24px;
            color: white;
            border-radius: 12px;
            font-size: 0.55rem;
            font-weight: 600;
            float: right;
          }

          &:first-child {
            @extend .m-b-xs;

            border-bottom: 1px solid $border-color;
            overflow: hidden;

            span {
              color: transparentize($light-grey, 0.5);
              height: 50px;
              line-height: 50px;
              margin-bottom: 0;
              letter-spacing: 0.3px;
              font-weight: 800;
              text-transform: uppercase;
            }
          }

          &:last-child {
            span {
              margin-bottom: 0;
            }
          }
        }
      }

      &:active {
        &>.table-cell-tooltip {
          opacity: 1;
          right: 20px;
          pointer-events: initial;
        }
      }
    }

    .table-size {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      min-height: 100px;
      max-height: 300px;
    }
  }
}

.colored {
  @extend .p-h-xs;

  display: inline-block;
  height: 28px;
  line-height: 28px;
  border-radius: 14px;
  color: white;
  font-size: 0.62rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clickable {
  cursor: pointer;
  border-radius: $border-radius-s;
  user-select: none;

  &:hover,
  &:focus {
    background-color: transparentize($light-grey, 0.3);
  }

  &:active {
    background-color: $light-grey;
  }
}

.table-item-action {
  display: inline-block;
  height: 24px;
  cursor: pointer;

  &:first-child {
    margin-right: 10px;
  }

  &>i {
    @extend .cta-transition;

    vertical-align: top;
    display: inline-block;
    font-size: 0.6rem !important;
    text-align: center;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background-color: $light-grey;
    color: white;
    border-radius: 50%;
    vertical-align: top !important;

    &:hover,
    &:focus,
    &:active {
      @extend .form-element--active;
    }
  }
}

.empty-table-disclaimer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;

  span {
    color: $light-grey;
    font-size: 0.7rem;
  }
}

// .table-col class goes from 1 to 24, which is a 12-grid multiplied by 2. This enables 0.5 steps in tables.
$table__grid__cols: 24;

@for $i from 1 through $table__grid__cols {
  .table-col-#{$i} {
    width: (100 / ($table__grid__cols / $i) - 0.1) * 1%; // TODO: Fix 0.5.
    user-select: none;

    &.isSortable {
      cursor: pointer;

      &:hover {
        border-radius: $border-radius-l;

        transition: 0.2s;
        background-color: $light-grey; // Change to desired hover background color
        cursor: pointer;
      }
    }
  }
}

.table-row {
  -webkit-animation: swing-in-top-fwd 1s cubic-bezier(.175, .885, .32, 1.275) both;
  animation: swing-in-top-fwd 1s cubic-bezier(.175, .885, .32, 1.275) both;
}

@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0
  }

  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1
  }
}

@media screen and (max-width: $mobile) {
  table {
    tr:not(.table-header):not(.table-body) {
      border-bottom: 1px solid $border-color;

      th,
      td {
        font-size: 9px;
        padding: 6px 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: break-spaces;
      }
    }
  }
}