.password_has_been_changed {
  @extend .flex-center;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-mm;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      @extend .m-t-m;
      @extend .m-b-ss;
    }

    .p5 {
      color: $off-black;
    }
  }

  &__button {
    @extend .w-240;
    @extend .h-48;
  }
}

@media screen and (max-width: $mobile) {
  .password_has_been_changed {
    margin-top: $space-l;
    align-items: flex-start;
    height: auto;

    &__content {
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;
    }

    &__title {
      align-items: flex-start;
      text-align: left;
      width: 100%;

      h1 {
        margin-top: $space-mm;
        font-weight: bold;
        font-family: $serif;
        font-size: 2.25rem;
      }

      .p5 {
        width: 100%;
      }
    }

    &__button {
      width: 100%;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}