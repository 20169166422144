.onboarding_header {
  @extend .p-v-m;

  display: block !important;
  height: 96px;
  width: 100vw;
  background: transparent;
  z-index: 100000;
  max-width: 100%;

  &__content {
    @extend .p-h-m;
    @extend .p-b-m;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .close_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $off-black;
    }

    p {
      padding-right: 16px;
      color: $off-black;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.onboarding_header.onboarding_header--absolute {
  position: absolute;
  top: 0;
}

.onboarding_border_bottom {
  width: 100%;
  height: 1px;
  border-bottom: 1.5px solid $light-grey;
}

@media screen and (max-width: $mobile) {
  .onboarding_header {
    padding: 0;
    height: 72px;
    position: relative;
    align-items: flex-start;

    &__content {
      padding: $space-s;
      width: 100%;
      position: relative;
      z-index: 100;
    }

    &__close {
      &>p {
        display: none;
      }
    }
  }
}