.question_line {
  .question_line__content {
    .question_line__question {
      @extend .m-b-m;

      display: flex;
      justify-content: center;
      align-items: center;

      p {
        @extend .p-l-xxs;
        @extend .p-r-xxs;

        color: $dark-grey;
        cursor: pointer;
      }
    }

    .question_line__popup {
      display: none;
    }

    .question_line__popup--display {
      display: block;
    }
  }
}