.prescription_expired {
  @extend .flex-center;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  min-height: 100vh;

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    @extend .m-b-m;
  }

  &__title {
    @extend .m-b-mm;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    h1 {
      @extend .m-t-s;
    }

    .p5 {
      width: 70%;
      color: #000000;
      text-align: center;
      font-size: 14px !important;
    }
  }
}

@media screen and (max-width: $mobile) {
  .prescription_expired {
    margin-top: $space-l;
    align-items: flex-start;
    height: auto;

    .prescription_expired__content {
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;

      .prescription_expired__title {
        width: 100%;
        text-align: left;
        align-items: flex-start;

        h1 {
          font-family: $serif;
          font-weight: bold;
          font-size: 2.25rem;
        }

        .p5 {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}