.toast {
    position: fixed;
    display: flex;
    box-shadow: -1px 5px 17px rgba(0, 0, 0, 0.39);
    border-radius: 4px;
    max-width: 538px;
    z-index: 100000000;
    background: #fff;
    right: 20px;
    top: 20px;
    font-family: "Poppins", sans-serif;
    margin-left: 1rem;
    transform: translateX(0);
    -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    &__side {
        width: 15px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        &--error {
            background: #FF0C0C;
        }

        &--success {
            background: #51C378;
        }
    }

    &__content {
        display: flex;
        padding: 1rem;

        &__icon {
            display: flex;
            flex-direction: column;
            justify-content: center;

            svg {
                height: initial;
            }
        }

        &__text {
            padding: 1rem;
            color: #fff;

            h5 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }

            &__message {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
        }

        &__close {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            svg {
                height: initial;
                cursor: pointer;
            }
        }
    }

    @-webkit-keyframes slide-left {
        0% {
            -webkit-transform: translateX(0);
        }

        100% {
            -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
        }
    }

    @keyframes slide-left {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        100% {
            -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
        }
    }

    &--error {
        @extend .toast;
        background: #C71414;
    }

    &--success {
        @extend .toast;
        background: #409A5F;
    }
}