.contact_information {
  @extend .m-t-xxl;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  display: flex;
  justify-content: center;

  a {
    color: $off-black;
    font-weight: 600;
  }

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 530px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-m;

    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .label--caps--normal {
      @extend .m-b-s;
    }

    .p5 {
      @extend .m-t-m;

      text-align: left;
      width: 75%;
    }
  }

  &__attention {
    @extend .m-b-mm;
    @extend .p-s;

    background-color: $extra-light-grey;
  }

  &__red_button {
    @extend .p-b-s;

    display: flex;
    align-items: center;

    p {
      @extend .p-l-xs;
    }
  }

  &__text {
    color: $off-black;
  }

  &__form {
    @extend .m-b-ss;

    width: 65%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__clinic_details {
    @extend .m-b-ss;
  }

  &__search {
    @extend .m-b-s;
  }

  &__line {
    @extend .m-b-m;

    display: flex;
    align-items: center;

    p {
      @extend .m-h-xs;

      text-align: center;
    }

    hr {
      width: 45%;
      border: 1px solid $light-grey;
    }
  }

  &__manually {
    @extend .m-b-s;

    text-align: left;
  }

  &__label {
    @extend .m-b-s;

    text-align: left;
  }

  &__button {
    @extend .m-b-mm;
    @extend .w-240;
    @extend .h-48;
  }

  &__email {
    @extend .m-b-l;

    width: 80%;

    p {
      text-align: center;
    }
  }
}

@media screen and (max-width: $mobile) {
  .contact_information {
    margin-top: $space-l;

    .contact_information__content {
      width: 100%;
      justify-content: flex-start;
      padding-left: $space-s;
      padding-right: $space-s;

      .contact_information__title {
        margin-bottom: $space-s;
        align-items: flex-start;
        text-align: left;
        width: 100%;

        h1 {
          margin-bottom: $space-s;
          font-family: $serif;
          font-weight: bold;
          font-size: 2.25rem;
        }
      }

      .contact_information__form {
        width: 100%;

        .contact_information__line {
          .line {
            border: 1px solid $light-grey;
          }

          .or {
            font-weight: normal;
            font-size: 0.75rem;
          }
        }
      }

      .contact_information__button {
        width: 100%;
      }

      .contact_information__email {
        width: 100%;

        p {
          text-align: left;
        }
      }
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}