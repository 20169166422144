.auth_header {
  @extend .p-v-m;

  display: block !important;
  height: 96px;
  width: 100vw;
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 100000;

  &__logo--white {
    display: block;
    cursor: pointer;
  }

  &__logo--black {
    display: none;
    cursor: pointer;
  }

  &__content {
    @extend .p-h-m;
    @extend .p-b-m;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;

    .close_icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      padding-right: 16px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $mobile) {
  .auth_header {
    padding: 0;
    height: 72px;
    position: relative;
    align-items: flex-start;
    background-color: white;

    &__logo--white {
      display: none;
    }

    &__logo--black {
      display: block;
    }

    &__content {
      padding: $space-s;
      width: 100%;
      position: relative;
      border-bottom: 2px solid $light-grey;
    }

    &__close {
      color: black;

      &>p {
        display: none;
      }
    }
  }
}