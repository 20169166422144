.repeat_prescription_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top: 0;
    position: relative;
    padding: 0 20px;
    background-color: $green-light;
    padding: 10%;

    .Lyphe_logo {
        margin: 20px 0 40px 0;
    }

    .back-to-previous-button {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 16px;
        cursor: pointer;

        p {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: $green-lighter;
            margin-left: 10px;
        }
    }

    &--content {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: #fff;
        padding: 56px 197px 115px 197px;
        border-radius: 8px;

        .divider {
            width: 107px;
            height: 4px;
            background-color: $green-light;
            margin: 32px 0;
        }

        .guideline {
            width: 100%;
            max-width: 505px;
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 48px;
                text-align: center;
                color: $green-light;
            }

            &--top-content {
                align-items: flex-start;
                width: 100%;
                border-bottom: 1px solid #D5D5D5;
                margin-bottom: 32px;
                padding-bottom: 32px;

                h2 {
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 48px;
                    color: $green-light;
                    margin-bottom: 24px;
                }

                p {
                    display: flex;
                    align-items: center;
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 21px;
                    line-height: 29px;
                    margin-bottom: 32px;

                    span {
                        min-width: 40px;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 22px;
                        background-color: $green-light;
                        text-align: center;
                        font-weight: 600;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-size: 22px;
                        line-height: 22px;
                        margin-right: 20px;
                    }
                }

                .p-link {
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 21px;
                    line-height: 29px;
                    margin-bottom: 0;
                }

                .link {
                    color: #F56052;
                    cursor: pointer;
                    text-decoration: none;
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 21px;
                    line-height: 29px;
                }
            }

            &--bottom-content {
                align-items: flex-start;
                width: 100%;

                p {
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 21px;
                    line-height: 36px;
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 32px;

                    span {
                        margin-top: 10px;
                        min-width: 12px;
                        min-height: 12px;
                        background: $green-light;
                        border-radius: 22px;
                        margin-right: 20px;
                    }
                }
            }

            .form_button {
                position: absolute;
                bottom: 56px;
                right: 56px;
                width: 148px;
                height: 58px;
            }
        }

        .no_eligible_products_page {
            width: 100%;
            max-width: 505px;
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 48px;
                text-align: center;
                color: $green-light;
            }

            &--top-content {
                align-items: flex-start;
                width: 100%;

                p {
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 21px;
                    line-height: 29px;
                    margin-bottom: 32px;
                }

                .link {
                    color: #F56052;
                    cursor: pointer;
                    text-decoration: none;
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 21px;
                    line-height: 29px;
                }
            }

            .no_eligible_products_page--button {
                width: 100%;
                height: 58px;
            }
        }

        .guideline--completed {
            width: 100%;
            height: 100%;
            max-width: 505px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 0;

            h1 {
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 48px;
                text-align: center;
                color: $green-light;
                margin-top: 33px;
            }

            F .divider {
                width: 107px;
                height: 4px;
                background-color: $green-lighter;
                margin: 32px 0;
            }

            h2 {
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 40px;
                color: $green-light;
                margin-bottom: 40px;
            }

            .completed-content {
                align-items: flex-start;
                width: 100%;

                p {
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 21px;
                    line-height: 36px;
                    margin-bottom: 24px;
                    display: flex;
                    align-items: flex-start;

                    span {
                        margin-top: 10px;
                        min-width: 12px;
                        min-height: 12px;
                        background: $green-light;
                        border-radius: 22px;
                        margin-right: 20px;
                    }
                }
            }

            .completed-button {
                width: 100%;
                height: 58px;
                margin-top: 70px;
            }

        }

        .form {
            width: 100%;
            height: 100%;
            max-width: 505px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .submit-button {
                width: 100%;
                height: 58px;
            }

            .form--heading {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                h1 {
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 48px;
                    text-align: center;
                    color: $green-light;
                }
            }

            .form--top-content {
                width: 100%;

                .form--top-content--heading {
                    width: 100%;
                    align-items: flex-start;
                    padding-bottom: 8px;
                    border-bottom: 2px solid $green-lighter;
                    margin-bottom: 32px;

                    h2 {
                        display: flex;
                        align-items: center;
                        font-family: 'Raleway';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 48px;
                        color: $green-light;

                        span {
                            min-width: 40px;
                            width: 40px;
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 22px;
                            background-color: $green-lighter;
                            text-align: center;
                            font-weight: 600;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-size: 22px;
                            line-height: 22px;
                            margin-right: 20px;
                        }
                    }
                }

                .your_details {
                    margin-bottom: 60px;
                    width: 100%;
                    align-items: flex-start;

                    span {
                        font-family: 'Raleway';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 36px;
                        margin-bottom: 16px;
                    }

                    p {
                        font-family: 'Raleway';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 36px;
                        color: #000000;
                        margin-bottom: 16px;
                    }
                }

                .your_medication {
                    p {
                        font-family: 'Raleway';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 28px;
                        color: #000000;
                    }

                    .info-banner {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        padding: 12px;
                        width: 100%;
                        // height: 80px;
                        background-color: rgba($color: #D5D5D5, $alpha: 0.22);
                        border-radius: 8px;
                        margin: 30px 0 45px 0;

                        p {
                            font-family: 'Raleway';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 28px;
                            margin-left: 13px;

                            .info-banner-link {
                                text-decoration: underline;
                                cursor: pointer;
                                color: #F56052;
                                font-weight: 600;
                            }
                        }

                        svg {
                            align-self: center;
                            margin-top: 3px !important;
                            min-width: 24px;
                        }
                    }

                    .comment-section {
                        display: flex;
                        flex-direction: column;
                        margin: 48px 0;

                        span {
                            font-family: 'Raleway';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 21px;
                            line-height: 36px;
                            color: #787878;
                            margin-bottom: 8px;
                        }

                        p {
                            font-family: 'Raleway';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 28px;
                            color: #000000;
                            margin-bottom: 8px;
                        }

                        textarea {
                            resize: none;
                            width: 100%;
                            height: 124px;
                            overflow: hidden;
                            overflow-x: auto;
                            background-color: #fff;
                            border: 1px solid #D5D5D5;
                            border-radius: 4px;
                            padding: 8px;
                            outline: none;
                            font-family: 'Raleway';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 36px;
                        }

                        ::placeholder {
                            font-family: 'Raleway';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 36px;
                            color: #BFBFBF;
                        }
                    }
                }

                .repeat_prescription_fee-banner {
                    width: 100%;
                    height: auto;
                    display: flex;
                    justify-content: space-between;
                    padding: 12px 24px;
                    align-items: center;
                    background-color: #F6F6F6;
                    border-radius: 4px;
                    margin-bottom: 24px;

                    p {
                        font-family: 'Raleway';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 48px;
                        color: #1E1E1E;
                    }
                }

                .card_details {
                    width: 100%;
                    padding-bottom: 40px;
                    margin-bottom: 16px;
                    border-bottom: 1px solid #D5D5D5;

                    p {
                        font-family: 'Raleway';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 21px;
                        line-height: 36px;
                        color: #787878;
                        margin-bottom: 8px;
                    }

                    input[type="text"] {
                        width: 100%;
                        height: 40px;
                        background-color: #fff;
                        border: 1px solid #D5D5D5;
                        border-radius: 4px;
                        outline: none;
                        font-family: 'Raleway';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 36px;
                        margin-bottom: 24px;
                        padding: 8px;
                    }

                    ::placeholder {
                        font-family: 'Raleway';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 36px;
                        color: #BFBFBF;
                    }

                    .stripe_card {
                        width: 100%;
                        padding: 8px;
                        border: 1px solid #D5D5D5;
                    }
                }

                .request_section {
                    width: 100%;

                    h2 {
                        font-family: 'Raleway';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 21px;
                        line-height: 36px;
                        margin-bottom: 30px;
                    }

                    .request_section_info {
                        display: flex;
                        justify-content: space-between;

                        p {
                            font-family: 'Raleway';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 21px;
                            margin-bottom: 30px;
                        }

                        &:last-child {
                            margin-bottom: 50px;
                        }
                    }
                }
            }
        }
    }

    .form_footer-p {
        width: 100%;
        text-align: center;
        max-width: 400px;
        margin-top: 32px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;

        a {
            text-decoration: none;
            cursor: pointer;
            color: #F56052;
        }
    }
}

@media (max-width: $mobile) {
    .repeat_prescription_form {
        padding: 0;

        .Lyphe_logo {
            margin: 135px 0 40px 0;
        }

        .back-to-previous-button {
            padding-left: 24px;
        }

        &--content {
            padding: 10%;
            border-radius: 0;
            padding-bottom: 25%;

            p {
                font-size: 16px !important;
            }

            a {
                font-size: 16px !important;
            }

            h1 {
                font-size: 32px !important;
            }

            h2 {
                font-size: 21px !important;
            }

            span {
                font-size: 21px !important;
            }

            .divider {
                width: 100%;
                max-width: 107px;
            }

            .guideline {
                .form_button {
                    position: unset;
                    align-self: flex-end;
                }
            }

            .guideline--completed {
                width: 100%;
                height: 100%;
                max-width: 505px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 0;

                .divider {
                    max-width: 107px;
                    width: 100%;
                }
            }

            .form {
                .form--top-content {
                    .your_details {
                        span {
                            font-size: 18px !important;
                        }
                    }

                    .your_medication {
                        p {
                            font-weight: 600;
                        }

                        .info-banner {
                            height: auto;

                            p {
                                font-size: 18px !important;
                            }
                        }
                    }
                }
            }
        }

        .form_footer-p {
            width: 100%;
            max-width: 100%;
        }
    }
}

.questionnaire {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 36px;
    margin-bottom: 32px;
    align-items: center;
    align-self: center;
    flex-direction: column;
    line-height: 32px;

    span {
        font-size: 18px !important;
    }

    hr {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    input[type="radio"] {
        transform: scale(1.5);
        margin: 16px;
    }

    &--item {
        display: list-item;
    }

    textarea {
        resize: none;
        width: 100%;
        height: 124px;
        overflow: hidden;
        overflow-x: auto;
        background-color: #fff;
        border: 1px solid #D5D5D5;
        border-radius: 4px;
        padding: 8px;
        outline: none;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
    }
}