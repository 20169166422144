.make_a_complaint {
  &__content {
    @extend .m-t-xl;
    @extend .m-h-auto;

    width: 30%;
    display: flex;
    flex-direction: column;
  }

  &__title {
    @extend .m-b-m;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      @extend .m-t-s;
      @extend .m-b-ss;
    }

    .p5 {
      width: 75%;
    }
  }

  &__form {
    @extend .m-b-m;
    @extend .m-h-auto;

    width: 75%;
    flex-direction: column;
  }

  &__button {
    @extend .w-240;
    @extend .m-b-m;
    @extend .m-h-auto;
  }
}

@media screen and (max-width: $mobile) {
  .make_a_complaint {
    &__content {
      margin-top: $space-l;
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;
      justify-content: flex-start;
    }

    &__title {
      align-items: flex-start;
      text-align: left;

      h1 {
        font-family: $serif;
        font-weight: bold;
        font-size: 2.25rem;
      }

      .p5 {
        width: 100%;
      }
    }

    &__form {
      margin-left: 0px;
      width: 100%;
    }

    &__button {
      margin-left: 0px;
      width: 100%;
    }

    &__buttons {
      width: 100%;
      flex-direction: column;

      &>div {
        width: 100%;
      }
    }

    &__interested {
      text-align: left;
      margin-bottom: $space-m;
    }

    &__social {
      width: 100%;
    }
  }
}