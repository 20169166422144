.reusable_button {
  background: none;
  border: 1px solid #D5D5D5;
  font-size: 14px;
  outline: none;
  width: 100%;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 5px;
  font-family: $sans-serif;
  transition: all 0.3s ease;

  &:hover {
    background: none;
  }

  &--view_button_hover:hover {
    background: #F1F1F1;
  }
}

.reusable_button--upload {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  position: static;
  width: 206px;
  height: 40px;
  left: 0px;
  top: 0px;

  background: #3E4057;
  transition: 0.3s;

  &:hover {
    background: #666c93;
  }

  border: 0px;
  border-radius: 4px;

  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 0px 16px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: #F6F6F6;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}