.account_upload {
  @extend .flex-center;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  z-index: 3;

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-l;

    flex-direction: column;
    text-align: center;

    &>p.label--caps--normal {
      @extend .p-b-s;
    }

    &>p.p5 {
      @extend .p-t-ss;

      text-align: left;
    }
  }
}

@media screen and (max-width: $mobile) {
  .account_upload {
    margin-top: $space-l;
    align-items: flex-start;
    height: auto;

    &__content {
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;

      .p5 {
        color: $off-black;
      }
    }

    &__title {
      text-align: left;
      width: 100%;

      .p5 {
        width: 100%;
      }
    }

    &__button {
      text-align: left;
      width: 100%;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}