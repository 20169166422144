.successful_payment {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        font-family: $sans-serif;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &__text {
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: #000;
        font-family: $sans-serif;
        font-style: normal;
        font-weight: normal;
    }

    &__button {
        margin-top: 1rem;
        height: 50px;
    }

}