.postDated_banner {
  @extend .m-b-s;
  @extend .m-h-s;

  width: auto;
  background: #F4E9D4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 8px;
  margin-top: 16px;

  &>div:first-child {
    @extend .m-r-xs;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div {
    font-size: 0.75rem;
    line-height: 22px;
  }
}

@media screen and (max-width: $mobile) {
  .postDated_banner {
    padding: 16px;
    gap: 8px;

    div {
      font-size: 0.875rem;
    }
  }
}