.mobile_dashboard_nav {
  font-family: $sans-serif;
  display: none;
  background: white;
  position: relative;
  margin-bottom: 8px;

  &>div {
    @extend .p-h-s;

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px !important;
  }

  &__left {
    &__search_holder {
      input {
        background: white !important;
      }

      label {
        font-size: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .mobile_dashboard_nav {
    display: block;
  }
}