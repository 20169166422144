.upload-image-mobile {
  @extend .flex-center;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  height: initial;
  min-height: calc(100vh - 96px);

  &__content {
    @extend .p-h-s;
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-xs;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    width: 80%;

    &>h1 {
      @extend .m-t-s;
      @extend .m-b-ss;
    }

    &>p.p5 {
      @extend .m-b-s;
    }
  }

  &__form {
    @extend .m-b-ss;

    width: 60%;
  }

  &__buttons {
    @extend .m-b-mm;

    width: 100%;
    display: flex;
    justify-content: space-between;

    &>div {
      @extend .w-240;
      @extend .h-48;
    }
  }

  &__button {
    @extend .w-240;
    @extend .h-48;
    @extend .m-b-s;
    @extend .m-t-s;
  }

  &__button-xxs {
    @extend .w-240;
    @extend .h-48;
    @extend .m-b-xxs;
    @extend .m-t-xs;
  }

  &__error {
    color: #FF0C0C;
    font-family: $sans-serif;
    margin-top: 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
      flex: 0.1;
    }

    &__message {
      flex: 0.9;
    }
  }

  &__add_patient {
    @extend .m-b-s;
  }

  &__terms {
    display: flex;
    justify-content: center;
  }

  &__or {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;

    &__text {
      color: black;
      flex: 0.1;
      font-family: $sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
    }

    &__line {
      height: 1px;
      flex: 0.45;
      background: #D5D5D5;
      ;
    }
  }
}

@media screen and (max-width: $tablet) {
  .upload-image-mobile {
    &__buttons {
      width: 100%;
      margin-bottom: $space-ss;
      flex-direction: column;

      &>div {
        width: 100%;
        margin-bottom: $space-s;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .upload-image-mobile {

    align-items: flex-start;
    height: auto;

    &__content {
      width: 100%;
      padding: 0;

      .upload-image-mobile__dropzone {
        width: 100%;
        padding: 0 $space-s;
        margin-bottom: 25px;
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: #D5D5D5;
        margin-bottom: $space-s;
      }
    }

    &__title {
      align-items: flex-start;
      text-align: left;
      width: 100%;
      padding: 0 $space-s;


      h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
      }
    }


    &__form {
      width: 100%;
      padding: 0 $space-s;

      .organisation_input {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #F0F4F2;
        border-radius: 10px;
        padding: $space-s;

        p {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 28px;
          letter-spacing: 1px;
          color: #787878;
        }
      }

      .prescriber-tracking_input {
        margin-top: $space-s;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 $space-s $space-s $space-s;
        background: #F6F6F6;
        border-radius: 10px;

        p {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 28px;
          letter-spacing: 1px;
          color: #787878;
          margin-top: $space-s;

          span {
            color: #B9B9B9;
          }
        }

        .prescirber-tracking--input {
          margin-top: 40px;

          transition: none !important;

          input {
            background: white !important;
            border: 1px solid #D5D5D5;
          }
        }
      }
    }

    &__button {
      width: 100%;
      padding: 0 $space-s;
    }

    &__button-xxs {
      width: 100%;
      padding: 0 $space-s;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}