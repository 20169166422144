.password_input_field__eye {
  position: absolute;
  right: 12px;
  top: 50%;
  cursor: pointer;
  text-transform: uppercase;
  transform: translateY(-50%);

  &--istext {
    top: 30%;
    font-style: normal;
    font-family: $sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    text-align: right;
    color: #000000;
  }
}