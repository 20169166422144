.notice_card {
  @extend .p-s;

  background: $extra-light-grey;
  text-align: left;

  &__header {
    @extend .m-b-s;

    display: flex;
    align-items: center;

    .p3--semi-bold {
      @extend .m-l-xs;
    }
  }

  &__text--padding {
    @extend .p-b-s;
  }

  &__bottom {
    @extend .p-t-s;
    border-top: 1.5px solid $light-grey;

    .label--caps--semi-bold {
      @extend .m-b-xxs;
    }
  }
}