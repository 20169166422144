.label_button {
  @extend .p-h-xs;
  @extend .p-v-xxs;
  @extend .p6;

  width: fit-content;
  color: white;
  border-radius: 2px;
  text-transform: uppercase;
  letter-spacing: 0.09rem;
}

.label_button--red {
  background: $error;
}

.label_button--green {
  background: $success;
}

.label_button--green-light {
  background: $green-light;
}

.label_button--orange {
  background: $orange-dark;
}