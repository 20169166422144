.product_details {
  .letter-spacing {
    letter-spacing: 0.1em;
  }
}

.product_details--column {
  @extend .product_details;

  &>div {
    @extend .m-b-xxs;
  }
}

.product_details--row {
  @extend .product_details;

  display: flex;

  &>div {
    @extend .m-r-xs;
  }
}

@media screen and (min-width: $tablet) and (max-width: 1024px) {
  .product_details--row {
    flex-direction: column;
  }
}