.btn {
  @extend .cta-transition;
  height: 70px;
  width: 280px;
  border: 1px solid $off-black;
  background-color: $off-black;
  color: white;
  cursor: pointer;
  outline: none !important;

  font-size: 0.82rem;
  letter-spacing: 0.5px;
  font-weight: 600;

  &:disabled {
    background-color: transparent;
    color: $off-black;
  }

  &:not(:disabled) {

    &:active,
    &:focus,
    &:hover {
      @extend .form-element--active;
    }
  }

  i {
    display: inline-block;
    margin: 15px;
  }

  &--white {
    border: 1px solid black;
    background-color: white;
    color: black;
  }

  &--transparent {
    border: 1px solid black;
    color: $off-black;
    background-color: transparent;
  }
}

.badge {
  @extend .cta-transition;

  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: white;
  border: 1px solid $off-black;
  background-color: $off-black;
  border-radius: 50%;
  font-size: 1.2rem;
  outline: none;
  cursor: pointer;

  &:disabled {
    background-color: transparent;
    color: $off-black;
  }

  &:not(:disabled) {

    &:active,
    &:focus,
    &:hover {
      @extend .form-element--active;
    }
  }
}