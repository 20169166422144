.previous {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &__orders {
    // 96 is the height of the header
    // 56 is the height of the bottom navbar
    min-height: calc(100vh - 96px - 56px);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}