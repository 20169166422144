.do_you_have_prescription {
  @extend .flex-center;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  a {
    color: $off-black;
    font-weight: 600;
  }

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-mm;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      @extend .m-t-s;
      @extend .m-b-m;
    }

    .p5 {
      width: 85%;
      text-align: left;
      color: $off-black;
    }
  }

  &__buttons {
    @extend .m-b-mm;

    width: 100%;
    display: flex;
    justify-content: space-between;

    &>div {
      @extend .w-240;
      @extend .h-48;
    }
  }

  &__email {
    @extend .m-b-m;

    width: 80%;
    text-align: center;
  }
}

@media screen and (max-width: $tablet) {
  .do_you_have_prescription {
    &__buttons {
      width: 100%;
      margin-bottom: $space-ss;
      flex-direction: column;

      &>div {
        width: 100%;
        margin-bottom: $space-s;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .do_you_have_prescription {
    margin-top: $space-l;
    align-items: flex-start;
    height: auto;

    &__content {
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;
    }

    &__title {
      align-items: flex-start;
      text-align: left;
      width: 100%;

      h1 {
        font-weight: bold;
        font-family: $serif;
        font-size: 2.25rem;
      }
    }

    &__email {
      width: 100%;
      text-align: left;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}