.terms_and_conditions_popup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .verification_card {
    width: 524px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid $light-grey;

    &__title {
      font-family: $sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 37px;
    }

    &__close {
      cursor: pointer;
    }
  }

  &__content {
    overflow-y: scroll;
    height: 500px;
    padding: 1rem;

    &::-webkit-scrollbar {
      width: 6px;
      background: $extra-light-grey;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background: $dark-grey;
      border-radius: 3px;
      background-clip: content-box;
    }

    p {
      @extend .p-r-xxs;
      @extend .p-b-s;

      font-size: 14px;
      line-height: 1.75rem;
      font-weight: 400;

      span {
        font-size: inherit;
        line-height: 1.75rem;
        font-weight: 400;

        &.bold {
          font-weight: 600;
        }
      }
    }

    a {
      color: $black;
    }

    h3 {
      font-size: 1.375rem;
      line-height: 1.875rem;
      margin-bottom: 2rem;
      font-weight: 500;
      font-family: $sans-serif;

      &.underline {
        text-decoration: underline;
      }
    }

    .underline {
      text-decoration: underline;
    }

    .italic {
      font-style: italic;
    }

    ul {
      padding-left: 16px;
      list-style: disc outside none;

      &.ul-roman {
        list-style-type: none;
        counter-reset: list;
        padding-left: 0;

        li {
          padding-left: 0;

          &::before {
            counter-increment: list;
            content: "(" counter(list, lower-roman) ") ";
            font-weight: 500;
            display: inline-block;
            width: 25px;
            vertical-align: top;
          }

          &:last-of-type {
            padding-left: 30px;

            &::before {
              display: none;
            }
          }

          span {
            display: inline-block;
            width: calc(100% - 35px);
            padding-left: 20px;
            font-weight: 400;
          }

          .bold {
            display: inline;
            padding: 0;
          }
        }
      }

      li {
        margin-bottom: 20px;
        padding-left: 10px;
        font-family: $sans-serif;

        &::marker {
          color: $black;
          font-size: 0.75rem;
        }

        span {
          font-size: 13px;
          line-height: 1.75rem;
          font-weight: 400;

          &.bold {
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .terms_and_conditions_popup {
    &__header {
      height: 10vh;
    }

    &__content {
      height: 90vh;
    }
  }
}