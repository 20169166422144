.what_happens_next_card {
  @extend .m-b-m;

  background-color: inherit;
  text-align: left;
  width: 300px;

  &__image {
    @extend .m-b-ss;

    height: 220px;
    width: 100%;

    &>img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    .p4-a {
      @extend .m-b-xs;
    }
  }
}