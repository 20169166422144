.checkbox {
    display: flex;
    align-items: center;
    width: 100px;

    &--w100 {
        width: 100%;
    }

    &__visual {
        width: 25px;
        min-width: 25px;
        height: 25px;
        border: 2px solid gray;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .label {
        margin-left: 10px;
        margin-right: 10px;
    }

    .custom_width {
        max-width: 350px;
    }

    input {
        display: none;
    }
}