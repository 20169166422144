.desktop_product {
  @extend .p-v-xs;

  border-bottom: 1px solid $extra-light-grey;

  &__row {
    @extend .p-l-s;

    height: fit-content;
    display: flex;
    align-items: center;
  }

  &__content {
    @extend .p-v-ss;
    @extend .p-h-s;

    width: 100%;
  }

  &__title {
    @extend .m-b-xs;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &>p {
      margin-right: auto;
    }
  }

  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    //width is 100 because text can change
    // and i don't want components
    // next to it to move when it does
    width: 100px;
    text-align: center;

    &>p {
      @extend .m-r-xs;

      font-weight: 500;
    }
  }
}

@media screen and (max-width: $mobile) {
  .desktop_product {
    display: none;
  }
}