.message_popup__overlay {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  &>div {
    border-radius: 20px;
    background-color: #353434;

    .message_popup__close {
      color: white;
      text-align: right;
      font-size: 1.5em;
      padding: 0.5em 1em;

      &:hover {
        cursor: pointer;
      }
    }

    .message_popup__content {
      padding: 2em 5em;
      text-align: center;

      .message_popup__text {
        margin-bottom: 3em;
        color: white;
      }
    }
  }
}