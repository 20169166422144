.desktop_dashboard__navigation {

  @extend .p-h-m;

  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-bottom: 1px solid #D5D5D5;
  z-index: 4;
  min-height: 56px;

  user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ */


  &>ul {
    list-style: none;

    li {
      @extend .p6;

      &:hover {
        cursor: pointer;
      }
    }

    a {
      text-decoration: none !important;
      width: 100%;
      text-align: center;
    }
  }

  .desktop_dashboard__links {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    li {
      @extend .p-v-xs;
      @extend .m-h-xs;

      color: $mid-grey;
      white-space: nowrap;
      border-bottom: 2px solid white;
      transition: border-bottom 0.2s ease-in;
    }

    &__search_holder {
      min-width: 350px;
      margin-right: 1rem;

      input {
        background: white;
      }
    }
  }

  .desktop_dashboard__pupup {
    z-index: -1000;
    width: 500px;
  }

  .desktop_dashboard__pupup {
    display: none;
    position: absolute;
    right: 50px;
    top: 60px;
  }

  .desktop_dashboard__actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: $sans-serif;

    .actions_upload_prescription {
      border-left: 2px solid $light-grey;
    }

    &:hover {
      &+.desktop_dashboard__pupup {
        display: block;
      }
    }

    li {
      @extend .p-h-xs;

      display: flex;
      align-items: center;

      &>div {
        @extend .m-h-xxs;
      }
    }
  }
}

@media (max-width: $mobile) {
  .desktop_dashboard__navigation {
    display: none;
  }
}