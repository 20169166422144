.terms_and_conditions {
  padding: 7px 10px 20px 15px;
  background-color: white;
  width: 100%;
  border: 2px solid $extra-light-grey;
  border-radius: 4px;
  position: relative;

  h5 {
    @extend .m-t-s;

    font-size: 1.25rem;
    margin-bottom: 1.875rem;
  }

  div {
    height: 150px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
      background: $extra-light-grey;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background: $dark-grey;
      border-radius: 3px;
      background-clip: content-box;
    }

    p {
      @extend .p-r-xxs;
      @extend .p-b-s;

      font-size: 1rem;
      line-height: 1.75rem;
      font-weight: 400;

      span {
        font-size: 1rem;
        line-height: 1.75rem;
        font-weight: 400;

        &.bold {
          font-weight: 600;
        }
      }
    }

    a {
      color: $black;
    }

    h3 {
      font-size: 1.375rem;
      line-height: 1.875rem;
      margin-bottom: 2rem;
      font-weight: 500;
      font-family: $sans-serif;

      &.underline {
        text-decoration: underline;
      }
    }

    ul {
      padding-left: 16px;
      list-style: disc outside none;

      &.ul-romans {

        padding-left: 0;

        li {
          padding-left: 0;
          list-style: square !important;

          &::before {

            font-weight: 500;
            display: inline-block;
            width: 25px;
            vertical-align: top;
          }

          &:last-of-type {
            padding-left: 30px;

            &::before {
              display: none;
            }
          }

          span {
            display: inline-block;
            width: calc(100% - 35px);
            padding-left: 20px;
            font-weight: 400;
          }

          .bold {
            display: inline;
            padding: 0;
          }
        }
      }

      li {
        margin-bottom: 20px;
        padding-left: 10px;
        font-family: $sans-serif;


        span {
          font-size: 1rem;
          line-height: 1.75rem;
          font-weight: 400;

          &.bold {
            font-weight: 600;
          }
        }
      }
    }
  }

  .terms_and_conditions__gradient {
    position: absolute;
    height: $space-m;
    width: calc(100% - 27px);
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, $extra-light-grey, white);
    opacity: 0.5;
    overflow-y: hidden;
  }
}