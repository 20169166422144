.generic_card {
  border-radius: 8px;
  width: 100%;
  padding: 22px;

  &--orange {
    background: #fbf8ef;
  }

  &--gray {
    background: #F6F6F6;
  }

  &--green {
    background: #F0F4F1;
  }

  &--red {
    background: #FFE5E6;
  }
}