.generic_banner {

  width: 100%;
  background: $extra-light-grey;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  &--green {
    background: #ECF8F0;
  }

  &--blue {
    background: #E9EDFB;
  }

  &--fontWeight-400 {
    .generic_banner__text__content {
      font-weight: 400;
    }
  }

  &__icon {
    @extend .m-r-xs;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 12px;
    flex: 0.1;
    align-self: start;
    margin-top: 5px;
  }

  &__text {
    display: flex;
    flex-direction: column;

    &__title {
      color: red;
      font-weight: 600;
      color: #000000;
    }

    &__content {
      font-family: $sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      text-align: left;
      flex: 0.9;

    }
  }
}