.drop_zone {
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__zone {
        cursor: pointer;
        background: #F6F6F6;
        width: 100%;
        border: 3px dashed #D5D5D5;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 300px;

        &:hover {
            background: #F2F2F2;
        }

        svg {
            height: 80px !important;
            width: 80px !important;
            margin-bottom: 1rem !important;
        }

        &__heading {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 21px;
            line-height: 20px;
            text-align: center;

            color: #484848;
            margin: 40px 0 30px 0;
        }

        &__number_of_files {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            font-family: $sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 26px;
            color: #484848;

            margin-bottom: 32px;
        }

        &__text {
            font-family: $sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            color: #000000;

            span {
                text-decoration: underline;
                font-family: $sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 26px;
                text-align: center;
                color: blue;
            }
        }
    }
}