.payment_requested_popup {
    &__icon {
        margin-top: 2rem;
    }

    &__text {
        font-family: $sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 6rem;
    }
}