.side_preview_content {
  padding-bottom: 1rem;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    font-family: $sans-serif;
    font-size: 22px;
    font-weight: 500;
    position: sticky;
    top: 0px;
    background: white;
    z-index: 2;
    border-bottom: 1px solid #D5D5D5;
  }

  &__informations {
    width: 80%;
    margin: auto;

    &__section_title {
      //   @extend .m-t-l;
      border-bottom: 1px solid #D5D5D5;
      margin-bottom: 1rem;
      margin-top: 48px;
      padding-bottom: 0.5rem;
      font-size: 16px;
      color: #787878;
      font-weight: 400;
      font-family: $sans-serif;
    }

    &__input_label {
      font-weight: 500;
      font-family: $sans-serif;
      font-size: 14px;
      color: #787878;
    }
  }

  &__actions-container {
    hr {
      margin: 5px 0;
    }
  }

  &__actions {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;

    &__cta_container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    &__cta {
      border-radius: 4px;
      padding: 8px 16px;
      font-size: 12px;
      line-height: 14px;
      margin: 5px;
      flex: 3;

      &__small {
        border-radius: 4px;
        padding: 8px 16px;
        font-size: 12px;
        line-height: 14px;
        margin: 5px;
        flex: 1;

        border: $error 1px solid;
        background: white;
        color: $error;

        &:not(:disabled) {

          &:active,
          &:focus,
          &:hover {
            cursor: pointer;
            background: $extra-light-grey;
            color: $error;
          }
        }

        &__contents {
          display: flex;
          flex-direction: row;

          svg {
            width: 15px;
          }

          span {
            padding-left: 5px;
          }
        }
      }
    }

    &__input_label {
      font-weight: 500;
      font-family: $sans-serif;
      font-size: 14px;
      color: #787878;
    }
  }
}