.product_preview {
    width: 0px;
    overflow: auto;
    max-height: 85vh;

    background: #f6f6f6;
    position: sticky;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    border: 1px solid $light-grey;

    &--show {
        width: auto;

        -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }

    -webkit-animation: fade-out 1s ease-out both;
    animation: fade-out 1s ease-out both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@media screen and (max-width: $mobile) {
    .product_preview {
        height: unset;
        max-height: unset;
    }
}