.user_details {
  @extend .m-b-l;

  width: 100%;

  &__header {
    @extend .m-b-ss;

    padding-bottom: 14px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1.5px solid $light-grey;
  }

  &__row {
    margin-bottom: 16px;

    .label--caps--normal {
      @extend .p-b-xs;

      color: $dark-grey;
    }
  }
}