.loading_order_to_be_paid_form {
  .order_payment_card__header {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 97px;
    width: 552px;
    max-width: 552px;

    &__text {
      font-family: $sans-serif;
      color: #787878;
      font-size: 22px;
      margin-top: 97px;
      text-align: center;
      font-weight: 500;
    }

    &__spinner {
      margin-top: 31.5px;
    }

    &__delay {
      background: #F6F6F6;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 31.5px;
      padding: 26px 16px 26px 16px;

      &__title {
        width: 100%;
        color: black;
        font-family: $sans-serif;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &__text {
        font-family: $sans-serif;
        line-height: 26px;
        font-size: 14px;
        margin-top: 15px;
      }
    }

    @media (max-width: $mobile) {
      width: 100%;
      min-width: 350px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}