.order_payment_card {
    max-width: 552px;
    ;
    box-shadow: 0px 11px 21px -6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background: #FFFFFF;
    height: fit-content;
    overflow: hidden;

    &__header {
        background: #13ae7d;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}