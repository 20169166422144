@import "./_variables";
@import "./components/typography/fonts/_fonts";
@import "./utils/_helpers";
@import "./utils/_layout";

// Forms
@import "./components/forms/_button";
@import "./components/forms/_checkbox";
@import "./components/forms/_text-input";
@import "./components/forms/_radio-input";
@import "./components/forms/_textarea";

// Typography
@import "./components/typography/_heading";
@import "./components/typography/_link";
@import "./components/typography/_paragraph";
@import "./components/typography/_span";

// Icons
@import "./components/_icons";

// Components
@import "./components/_table";

// Tooltip contents
@import "./components/tooltipContent/productNoteTooltipContent";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

body {
  font-size: $body-font-size;
  // background-color: $background-color;
  background-color: white;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

.no_scroll {
  overflow-y: hidden;
}

// zendesk has a style that centers svg-s
// we need to override that
svg {
  margin: 0 !important;
  height: 100%;
}