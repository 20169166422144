.prodcut_select {
    width: 100%;

    .product_select--info {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    .m-b-0 {
        margin-bottom: 0;
    }

    .prodcut_select-select {
        margin-bottom: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;

        p {
            margin-right: 16px;
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
        }

        .MuiInput-root {
            border: 1px solid #D5D5D5;
            border-radius: 4px;

            .MuiSelect-select.MuiSelect-select {
                padding-left: 8px;
            }
        }
    }
}