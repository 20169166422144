// General helper classes
.separator {
  @extend .m-v-l;

  display: block;
  width: 100%;
  border-bottom: 1px solid $off-black;
  opacity: 0.4;
}

// Forms
.form-element--active {
  border-color: $tertiary;
  background-color: $tertiary;
  color: white;
}

.input--hidden {
  // Hide input but leave it selectable.
  position: absolute;
  opacity: 0;
}

// Transitions
.cta-transition {
  -moz-transition: background-color $form-transition-duration ease-in,
    color $form-transition-duration ease-in,
    border-color $form-transition-duration ease-in;
  -webkit-transition: background-color $form-transition-duration ease-in,
    color $form-transition-duration ease-in,
    border-color $form-transition-duration ease-in;
  -o-transition: background-color $form-transition-duration ease-in,
    color $form-transition-duration ease-in,
    border-color $form-transition-duration ease-in;
  transition: background-color $form-transition-duration ease-in,
    color $form-transition-duration ease-in,
    border-color $form-transition-duration ease-in;
}

.width-transition {
  -moz-transition: width $form-transition-duration ease-in;
  -webkit-transition: width $form-transition-duration ease-in;
  -o-transition: width $form-transition-duration ease-in;
  transition: width $form-transition-duration ease-in;
}

.animated-border {
  @extend .width-transition;

  content: "";
  position: absolute;
  top: 59.1px;
  left: 0;
  width: 0;
  height: 2px;
}

@-moz-document url-prefix() {
  .animated-border {
    @extend .width-transition;

    content: "";
    position: absolute;
    top: 59.5px !important;
    left: 0;
    width: 0;
    height: 2px;
  }

  .animated-border--red {
    @extend .width-transition;

    content: "";
    position: absolute;
    top: 59.5px !important;
    left: 0;
    width: 0;
    height: 2px;
    background-color: $error;
  }
}

.animated-border--blue {
  @extend .animated-border;
  background-color: $blue;
}

.animated-border--red {
  @extend .width-transition;

  content: "";
  position: absolute;
  top: 59.1px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: $error;
}

.animated-border--black {
  @extend .animated-border;

  background-color: $black;
}

.animated-border--black--textarea {
  @extend .animated-border--black;

  top: 99% !important;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}