.product_description {
  @extend .p-h-s;

  padding-left: 115px;
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
  max-height: 0;
  height: auto;

  &__text {
    @extend .p-b-ss;

    min-width: 385px;
    width: 43%;
  }
}

.product_description--show {
  max-height: 100vh;
}

@media screen and (max-width: $mobile) {
  .product_description {
    padding-left: 0;
  }
}