.repeat_prescription_loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    position: relative;

    .repeat_prescription_loader--icons {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 505px;
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        text-transform: uppercase;
        margin-top: 10px;
    }

    h1 {
        font-family: 'Frank Ruhl Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 54px;
        text-align: center;
        margin-top: 10px;
        max-width: 100%;
    }
}

@media (max-width: $mobile) {
    .repeat_prescription_loader {
        padding: 0 40px;
    }
}