.filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__text {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      @extend .p-l-xs;

      color: $off-black;
      font-weight: 400;
    }
  }

  .filter__overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.5s ease-in-out;
    z-index: 10000;
    pointer-events: none;
  }

  .filter__overlay.show {
    background-color: rgba(255, 255, 255, 0.7);
    pointer-events: all;
  }

  .filter_card {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    z-index: 999999;

    position: fixed;
    top: 0;
    left: -100%;
    transition: all 0.5s ease-in-out;

    &__content {
      height: 100vh;
      width: 320px;
      background: white;

      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    }

    &__head {
      @extend .p-h-s;
      @extend .m-b-ss;

      height: 55px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $light-grey;
    }

    &__body {
      @extend .p-h-s;
      // 55px - filter card head height
      // 30px - filter card head margin bottom
      // 30px - buttons margin bottom
      // 40px - button height
      // 10px - button bottom margin
      // 20px - buttons bottom margin
      height: calc(100vh - 55px - 30px - 40px - 10px - 40px - 10px - 20px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
        background: $extra-light-grey;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-grey;
        border-radius: 3px;
        // border: 3px solid transparent;
        background-clip: content-box;
      }
    }

    &__search {
      @extend .m-b-s;

      width: 100%;
    }

    &__filtering {
      ul {
        li {
          @extend .m-b-xs;
        }
      }
    }

    &__sort_by {
      @extend .m-b-ss;

      ul {
        color: $mid-grey;
      }
    }

    &__sort_by__title {
      @extend .m-b-s;

      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &__list_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &__list_item_black {
      @extend .p5;

      color: $black;
    }

    &__filter_category {
      @extend .m-b-s;

      ul {
        color: $mid-grey;
      }
    }

    &__filter_category__title {
      @extend .m-b-s;
    }

    &__buttons {
      @extend .p-h-s;
      @extend .m-b-s;

      div {
        @extend .h-40;
        @extend .m-b-xs;
      }
    }
  }

  .filter_card.show {
    left: 0;
  }
}

@media screen and (max-width: $mobile) {
  .filter {
    &__text {
      p {
        display: none;
      }
    }

    .filter_card {
      width: 100%;
      justify-content: flex-start;

      &__content {
        width: 100%;
      }

      &__head {
        height: 72px;
        margin-bottom: 15px;

        .label--caps {
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: 0.1em;
          text-transform: uppercase;
        }

        .label_link {
          font-size: 0.875rem;
        }
      }

      &__body {
        height: calc(100vh - 72px - 15px - 40px - 15px);
      }

      &__search {
        margin-bottom: 10px;
      }

      &__list_item {
        p {
          font-family: $sans-serif;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
        }
      }

      &__list_item_black {
        p {
          font-family: $sans-serif;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          color: $black;
        }
      }

      &__sort_by__title {
        p {
          font-family: $sans-serif;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 500;
        }
      }

      &__product_type__title {
        p {
          font-family: $sans-serif;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 500;
        }
      }

      &__buttons {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;

        div {
          width: 50%;
        }
      }
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}