.verification_card {
  width: 407px;
  height: auto;
  background: white;
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  z-index: 3;
  position: relative;

  .verification_card__header {
    @extend .p-h-s;

    display: none;
  }

  .verification_card__close {
    @extend .p-t-s;
    @extend .p-r-s;

    top: 0px;
    right: 0px;
    position: absolute;
    text-align: right;
    cursor: pointer;
  }

  .verification_card__content {
    @extend .p-h-ss;
    @extend .p-t-xs;
    @extend .m-v-s;

    display: flex;
    flex-direction: column;
    align-items: center;

    .verification_card__image {
      @extend .m-v-l;
    }

    .verification_card__no_image {
      @extend .m-v-xs;
    }

    .verification_card__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h1 {
        @extend .m-b-ss;

        width: 70%;
      }

      p {
        @extend .m-b-m;
        text-align: center;
      }
    }

    .verification_card__disclaimer {
      background-color: #f0f0f0;
      padding: 10px;
      margin: 10px 0;
      border-radius: 4px;
      position: relative;
      display: flex;
      align-items: center;
    }

    .disclaimer-text {
      margin-left: 16px;
      flex-grow: 1;
    }

    .verification_card__buttons {
      text-align: center;

      &>div {
        @extend .w-240;
        @extend .h-40;
        @extend .m-b-s;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .verification_card {
    width: 100%;
    height: 100vh;
    box-shadow: none;
    overflow-y: auto;

    .verification_card__header {
      padding-left: $space-s;
      padding-right: $space-s;
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $light-grey;

      p {
        text-transform: uppercase;
      }
    }

    .verification_card__close {
      display: none;
    }

    .verification_card__content {
      padding-left: $space-s;
      padding-right: $space-s;

      .verification_card__title {
        width: 100%;

        p {
          text-align: left;
        }
      }

      .verification_card__buttons {
        margin-bottom: $space-m;
        width: 100%;

        &>div {
          width: 100%;
        }
      }
    }
  }
}