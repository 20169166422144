.active_prescriptions_doctor_dashboard {
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__search {
        width: 90%;
        display: flex;
        justify-content: flex-end;

        &__holder {
            min-width: 300px;
            padding-right: 50px;
            margin-top: 20px;
        }
    }

    &__table_holder {
        border-radius: 5px;
        margin: 20px 0;
        width: 90%;
        padding: 1rem;
        overflow-x: scroll;
    }
}