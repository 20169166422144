.tertiary_cta {
  @extend .p-h-s;
  @extend .p-v-xs;
  @extend .p6;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 100%;
  transition: 0.3s;

  span {
    @extend .m-r-xs;

    outline: none;
  }

  &:disabled {
    background: $light-grey;
    color: white;

    path {
      fill: $extra-light-grey;
    }
  }
}

.tertiary_cta--black {
  @extend .tertiary_cta;

  background: $dark-grey;
  border: 1px solid white;
  color: $extra-light-grey;

  &:not(:disabled) {

    &:active,
    &:focus,
    &:hover {
      cursor: pointer;
      background: white;
      color: $dark-grey;
      border-color: $dark-grey;

      path {
        fill: $dark-grey;
      }
    }
  }
}

.tertiary_cta--white {
  @extend .tertiary_cta;

  background: white;
  color: $off-black;
  border: 1px solid $light-grey;

  &:not(:disabled) {

    &:active,
    &:focus,
    &:hover {
      cursor: pointer;
      background: $peach-medium;
      color: white;
      border-color: $peach-medium;

      path {
        fill: white;
      }
    }
  }
}

.tertiary_cta--blue {
  @extend .tertiary_cta;

  background: $blue;
  color: $extra-light-grey;

  &:not(:disabled) {

    &:active,
    &:focus,
    &:hover {
      cursor: pointer;
      background: $purple;
      color: $extra-light-grey;

      path {
        fill: $extra-light-grey;
      }
    }
  }
}