.prescription_preview_container {
  position: relative;

  &__buttons {
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 1;

    &--top {
      top: 70px;
      left: 5px;
    }

    &__clear_button {
      cursor: pointer;
      background: blue;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
      min-width: 36px;
      min-height: 36px;
      margin-left: 10px;
      margin-bottom: 10px;

      &:hover {
        background: rgba(0, 0, 0, 0.9);
      }
    }
  }

  .lb-container {
    position: static !important;
    min-height: 300px;
    max-height: 300px;
    width: 100%;
    z-index: 0 !important;

    .lb-header {
      z-index: 0 !important;
    }

    .lb-icon-close {
      display: none;
    }
  }
}