.account_footer__social_media {
  display: flex;
  align-items: center;

  .account_footer__social_media__text {
    padding-right: 14px;

    p {
      color: $off-black;
      padding-bottom: 0;
      font-size: 0.625rem;
      letter-spacing: 1.5px;
    }
  }

  .account_footer__social_media__icons {
    display: flex;

    .account_footer__social_media__icon {
      &:not(:last-child) {
        margin-right: 17px;
      }
    }

    img {
      padding-right: 16px;
    }

    img:last-child {
      padding-right: 0px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .account_footer__social_media {
    margin-top: $space-mm;
    flex-direction: row;
    align-items: center;

    .account_footer__social_media__text {
      margin-right: 16px;

      p {
        font-size: 0.75rem;
      }
    }

    .account_footer__social_media__icons {
      img {
        padding-right: 24px;
      }

      img:last-child {
        padding-right: 0px;
      }

      svg {
        width: 28px;
      }

      .account_footer__social_media__icon {
        &:not(:last-child) {
          margin-right: 25px;
        }
      }
    }
  }
}