.before_we_start {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  display: flex;
  height: 100vh;
  position: relative;

  &__left_slot {
    position: relative;
    width: 42%;
    height: 100%;
    background: url("../../../assets/images/before_we_start_doctor.png") center no-repeat;
    background-size: cover;
  }

  &__left_slot--patient {
    background: url("../../../assets/images/before_we_start_patient_carer.png") center no-repeat;
    background-size: cover;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 21.56%);
  }

  &__right_slot {
    @extend .p-l-xxl;
    @extend .p-r-s;

    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;

    .before_we_start__title {
      p {
        @extend .p-b-xs;
      }

      h1 {
        @extend .p-b-ss;
      }

      .p4 {
        @extend .p-b-m;

        width: 70%;
        color: $off-black;
      }
    }

    .before_we_start__steps {
      ol {
        @extend .p-b-ss;

        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
      }

      li {
        @extend .p-b-ss;
        display: flex;
        align-items: center;

        p {
          @extend .p4-a;
          @extend .p-l-s;
        }
      }
    }

    .before_we_start__button--wrapper {
      display: flex;

      .before_we_start__button:first-of-type {
        margin-right: 24px;
      }
    }

    .before_we_start__button {
      @extend .w-240;
      @extend .h-48;
      @extend .m-b-m;

      display: flex;
      justify-content: flex-start;
    }

    .before_we_start__link {
      color: $off-black;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: $tablet) {
  .before_we_start {
    justify-content: center;

    &__left_slot {
      display: none;
    }

    &__right_slot {
      padding-left: $space-s;
    }
  }

  .before_we_start__button--wrapper {
    flex-direction: column;

    .before_we_start__button:first-of-type {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: $mobile) {
  .before_we_start {
    height: auto;
    display: flex;
    flex-direction: column;

    &__left_slot {
      display: block;
      height: 297px;
      width: 100%;
      background: url("../../../assets/images/before_we_start_patient_carer_mobile.png.png") no-repeat cover;
    }

    &__right_slot {
      width: 100%;
      padding-top: $space-m;
      padding-left: $space-s;
      padding-right: $space-s;
      padding-bottom: $space-m;

      .before_we_start__title {
        align-items: flex-start;
        text-align: left;
        width: 100%;
        margin-bottom: 30px;

        h1 {
          font-family: $serif;
          font-weight: bold;
          font-size: 2.25rem;
          padding-bottom: $space-s;
        }

        .p4 {
          width: 100%;
          padding-bottom: $space-ss;
        }
      }

      .before_we_start__steps {
        ol {
          padding-bottom: $space-ss;

          li {
            padding-bottom: $space-ss;
          }
        }
      }

      .before_we_start__button {
        width: 100%;
      }
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}