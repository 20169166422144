.prescription_card_for_side_preview {
  font-family: $sans-serif;

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 18px;
  }

  &__section_title {
    font-size: 14px;
    color: #787878;
  }

  &__normal_text {
    font-size: 16px;
    color: #1E1E1E;
  }

  &__large_text {
    font-size: 21px;
    color: #1E1E1E;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    overflow-wrap: anywhere;

    &--link {
      color: #3B5EDB;
    }
  }

  &__circle {
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
    background: #3B5EDB;
    border-radius: 50%;
    align-self: flex-start;
    margin-top: 6px;

    &--large {
      height: 20px;
      width: 20px;
    }

    &--red {
      background: #FF0C0C;
    }

    &--green {
      background: #51C378;
    }

    &--blue {
      background: #3B5EDB;
    }

    &--orange {
      background: #E7CC9E;
    }

    &--gray {
      background: #D5D5D5;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #1E1E1E;
    font-weight: 400;
  }

  &__product {
    color: #1E1E1E;
    font-size: 16px;
  }
}