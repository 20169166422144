.mobile_header {
  background: white;
  display: none;
  position: sticky;
  top: 0px;
  height: auto;
  width: 100%;
  border-bottom: 1px solid $light-grey;
  // to be on top of
  // navigation dashboard overlay
  z-index: 2;

  &__container {
    height: 66px;
    border-bottom: 1px solid #D5D5D5;
  }

  &>div {
    @extend .p-s;

    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
  }

  .mobile_nav {
    max-height: 0;
    min-height: 0;
    height: auto;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background: white;
    flex: 1;

    a {
      color: $off-black;
      text-decoration: none;
      align-self: flex-start;
      margin-bottom: 17px;
    }

    ul {
      list-style: none;
    }

    .mobile_nav__up_slot {
      border-bottom: 1px solid #D5D5D5;
      @extend .m-h-ss;
      @extend .m-t-m;
      @extend .m-b-s;

      display: flex;
      flex-direction: column;

      .h7-a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 32px;
        letter-spacing: 0.5px;
      }

      .active {
        padding: 10px;
        background: #13ae7d;
      }

      .mobile_nav__upload_prescription {
        @extend .p-t-s;
        @extend .m-b-xs;

        border-top: 1px solid $light-grey;
      }
    }

    .mobile_nav__down_slot {
      @extend .m-h-ss;

      display: flex;
      flex-direction: column;

      div:first-child {
        @extend .m-b-s;

        ul {
          @extend .p3;

          li {
            @extend .p-b-s;

            font-weight: 400;

            cursor: pointer;
          }

          .mobile_nav__my_account {
            @extend .label--caps;
            @extend .m-b-xxs;

            color: #AEAEAE;
            cursor: default;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 2px;
          }

          .mobile_nav__learn {
            a {
              display: flex;
              justify-content: left;
              align-items: center;
              margin: 0;

              &>div {
                margin-bottom: 0;
              }
            }

            div:nth-child(2) {
              margin-left: 10px;
            }
          }

          .mobile_nav__log_out {
            border-bottom: 1px solid #D5D5D5;
            font-weight: 550;
          }
        }
      }

      .mobile_nav__social {
        display: flex;
        justify-content: center;

        a {
          @extend .p-b-s;
          padding-right: 24px;
        }
      }
    }
  }

  .mobile_nav.show_nav {
    max-height: calc(100vh - 66px);
    min-height: calc(100vh - 66px);
    display: flex;
    flex-direction: column;
    // so that you can't scroll
    // pass the end of navigation
    overflow-y: scroll;
    flex: 1;

    .footer_mobile {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: auto;
      padding: 0 16px;

      a {
        width: 50%;
        align-self: flex-end;
      }
    }
  }
}

.mobile_header.mobile-header--purple {
  border-bottom: none;

  &>div {
    background: $purple;
  }
}

.mobile_header.mobile-header--green {
  border-bottom: none;

  &>div {
    background: $green-light;
  }
}

@media screen and (max-width: $mobile) {
  .mobile_header {
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
}