.support {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  .support__banner {
    @extend .p-t-xxl;

    color: $black;
    width: 100%;
    background: $green-light;
    display: flex;
    justify-content: center;

    &>div {
      @extend .p-h-s;

      width: 670px;

      h1 {
        @extend .p-b-ss;
      }

      p {
        @extend .p-b-xxl;

        color: $off-black;
      }

      a {
        @extend .p4;

        color: $off-black;
        font-weight: 500;
      }
    }
  }

  .support__body {
    @extend .m-t-xl;
    @extend .m-b-xxl;

    display: flex;
    justify-content: center;

    width: 100%;
  }

  .support__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .support__faqs {
    width: 100%;
  }

  .support__footer {
    border-top: 4px solid $extra-light-grey;
  }
}

@media screen and (max-width: $mobile) {
  .support {
    .support__banner {
      &>div {
        h1 {
          margin-top: $space-l;
          font-size: 2.2rem;
          font-weight: 700;
        }

        p {
          font-size: 0.9rem;
          font-weight: 300;
        }

        a {
          font-size: 0.9rem;
          font-weight: 500;
        }
      }
    }

    .support__body {
      margin-top: $space-l;
      margin-bottom: $space-m;
      width: 100%;
    }

    .support__faqs {
      h1 {
        font-size: 1.4rem;
        font-weight: 500;
      }
    }

    .support__footer {
      border-top: 16px solid $extra-light-grey;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}