.p1 {
  font-weight: normal;
  font-family: $sans-serif;
  font-size: 1.56rem;
}

.p1--semi-bold {
  @extend .p1;
  font-weight: 500;
}

.p2 {
  font-family: $sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
}

.p3 {
  font-family: $sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
}

.p3--semi-bold {
  @extend .p3;
  font-weight: 500;
}

.p4 {
  font-family: $sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
}

.p4--normal {
  @extend .p4;

  font-weight: normal;
}

.p4-a {
  @extend .p4;

  font-weight: 500;
}

.p5 {
  font-family: $sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}

.p5--normal {
  @extend .p5;

  font-weight: normal;
}

.p5--semi-bold {
  @extend .p5;

  font-weight: 500;
}

.p5--bold {
  @extend .p5;

  font-weight: 600;
}

.p6 {
  font-family: $sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: normal;
}

.p6--semi-bold {
  @extend .p6;

  font-weight: 500;
}

.p6--bold {
  @extend .p6;

  font-weight: 600;
}

.p7 {
  font-family: $sans-serif;
  font-size: 0.625rem;
}

.p7--normal {
  @extend .p7;

  font-weight: normal;
}

.text_link {
  font-family: $sans-serif;
  font-size: 0.75rem;
  font-weight: normal;
  text-decoration: underline;
  color: #3B5EDB;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.caps {
  text-transform: uppercase;
}

//labels
.label {
  font-family: $sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
}

.label--smaller {
  @extend .label;
  font-size: 12px;
}

.label--smaller--semi-bold {
  @extend .label--smaller;
  font-weight: 500;
}

.label--smaller--caps {
  @extend .label--smaller;
  text-transform: uppercase;
}

.label--smaller--caps--poppins {
  @extend .label--smaller--caps;
  font-family: "Poppins";
  letter-spacing: 0.1em;
  color: #000000;
  font-weight: 500;
}

.label--smaller--caps--semi-bold {
  @extend .label--smaller;
  font-weight: 500;
}

.label--light {
  @extend .label;
  font-weight: 300;
}

.label--semi-bold {
  @extend .label;
  font-weight: 500;
}

.label--caps {
  font-family: $sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.label--caps--normal {
  @extend .label--caps;
  font-weight: normal;
}

.label--caps--semi-bold {
  @extend .label--caps;
  font-weight: 600;
}

.label--caps--smaller {
  @extend .label--caps;
  font-size: 0.625rem;
}

.label_link {
  font-family: $sans-serif;
  font-weight: 500;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

// legacy
p,
.p {
  font-family: $sans-serif;
  font-size: 0.82rem;
  font-size: 0.82rem;
  font-weight: 600;
}

.l-1 {
  font-size: 0.875rem;
  font-weight: 300;
  font-style: normal;
  font-family: $sans-serif;
}