.patient_feedback--banner {
    width: 100%;
    height: 112px;
    background-image: url(../../../assets/images/Megaphone.svg);
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    background-color: #3E4057;
    border-radius: 10px;
    padding: 24px;
    align-items: center;
    font-family: "Poppins", sans-serif;

    .patient_feedback--content {
        width: auto;

        h3 {
            margin-bottom: 13px;
            color: #fff;
            font-weight: 500;
            font-size: 22px;
        }

        p {
            color: #fff;
            font-weight: 300;
            font-size: 16px;
        }
    }

    .patient_feedback--button {
        height: 46px;
    }
}

@media screen and (max-width: 1303px) {
    .patient_feedback--banner {
        .patient_feedback--content {
            width: 60%;
        }
    }
}

@media (max-width: $mobile) {
    .patient_feedback--banner {
        margin-top: 40px;
        background-position: right -30% bottom -65%;
        flex-direction: column;
        align-items: flex-start;
        height: auto;

        .patient_feedback--content {
            width: 100%;

            .p {
                font-size: 14px;
            }
        }

        .patient_feedback--button {
            margin-top: 24px;
        }
    }
}