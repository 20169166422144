.faq {
  color: $black;
  -webkit-transition: color 0.5s ease-in-out;
  -moz-transition: color 0.5s ease-in-out;
  -ms-transition: color 0.5s ease-in-out;
  -o-transition: color 0.5s ease-in-out;
  transition: color 0.5s ease-in-out;

  &:last-child {
    .faq__line--display {
      display: none;
    }
  }

  .faq__question {
    @extend .p-v-s;

    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .faq__line--display {
    hr {
      height: 1px;
      border: none;
      background: $light-grey;
    }
  }

  .faq__answer {
    -webkit-transition: max-height 0.5s ease-in-out, margin-top 0.5s ease-in-out,
      margin-bottom 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out, margin-top 0.5s ease-in-out,
      margin-bottom 0.5s ease-in-out;
    -ms-transition: max-height 0.5s ease-in-out, margin-top 0.5s ease-in-out,
      margin-bottom 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out, margin-top 0.5s ease-in-out,
      margin-bottom 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out, margin-top 0.5s ease-in-out,
      margin-bottom 0.5s ease-in-out;
    overflow: hidden;
    max-height: 0;
    height: auto;
  }

  .faq__answer--display {
    margin: 32px 0;
    max-height: 100vh;
  }
}

.faq_icon {
  &:hover {
    cursor: pointer;
  }
}

.faq--grey {
  @extend .faq;

  color: $light-grey;
}