.quaternary_cta {
  display: flex;
  align-items: center;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  span:first-child {
    @extend .m-r-xs;
  }
}

.quaternary_cta--white {
  color: white;
}

.quaternary_cta--black {
  color: black;
}

.quaternary_cta--red {
  color: #FF0C0C;
  font-weight: bold;
}