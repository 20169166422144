.doctor_patients_table {
    width: 100%;
    min-width: 1000px;
    border-collapse: separate;
    border-spacing: 0 6px;
    background-color: #f6f6f6;

    thead {
        width: 100% !important;
        color: red;
    }

    tr {
        border-bottom: none !important;
    }

    th,
    td {
        line-height: normal !important;
        font-family: "Poppins", sans-serif;
        word-break: break-word;

        &.left {
            justify-content: flex-start;
        }

        &.right {
            justify-content: flex-end;
        }
    }

    th {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        padding-bottom: 10px !important;
        padding-top: 10px !important;
    }

    td.td_patient_status {
        font-family: "Poppins", sans-serif;
        font-size: 10px;
        font-weight: 500;
    }

    td.td_patient_name {
        font-size: 14px;
    }

    td {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: normal;
        min-height: 44px;
        overflow: initial !important;

        .doctor_patients_table__button--green {
            color: #13ae7d;
        }

        .doctor_patients_table__button--gray {
            color: #787878;
        }

        button {
            border: none;
            background: none;
        }

        i {
            cursor: pointer;
        }

        a {
            text-decoration: none;
            color: black;
            display: flex;
            align-items: center;
        }

        .disabled {
            pointer-events: none;
            color: rgb(128, 127, 127);
        }
    }

    tbody {
        tr {
            td {
                background-color: white;
                min-height: 57px;

                &:first-of-type {
                    border-radius: 5px 0 0 5px;
                }

                &:last-of-type {
                    border-radius: 0 5px 5px 0;
                }

                &.loading_row {
                    min-height: 400px;
                }
            }
        }

        tr:first-of-type {
            border-top: 1px #d5d5d5 solid;
            padding-top: 1rem !important;

            td {
                margin-top: 10px;
            }
        }
    }

    .col-left {
        justify-content: flex-start;
    }

    &__mtd {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 18px;
        position: relative;
        font-family: "Poppins", sans-serif;

        &__information {
            background: #ffffff;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
            padding: 1.5px 9px 105.5px 11px;
            border-radius: 5px;
            position: absolute;
            top: -50px;
            left: 100px;
            font-size: 10px;
            min-width: 250px;
            height: 150px;

            &__title {
                font-size: 10px;
                font-weight: 500;
                display: flex;
                justify-content: space-between;

                span {
                    cursor: pointer;
                }
            }
        }
    }

    tfoot {
        tr {
            display: flex;
            justify-content: flex-end;
            padding: 0.3rem;

            .doctor_patients_table__pagination {
                span {
                    margin: 0 0.5rem;
                    cursor: pointer;
                    color: #484848 !important;
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    background-color: #ffffff !important;
                    padding: 5px 8px;
                    font-weight: bold;

                    &.active_page {
                        color: white !important;
                        background-color: black !important;
                    }
                }

                button {
                    border: none;
                    background-color: #ffffff !important;
                    cursor: pointer;
                    padding: 0.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    div {
                        margin: auto;
                        width: 100%;
                    }
                }

                button:disabled {
                    background-color: #d4d4d4 !important;
                    opacity: 0.6 !important;
                }
            }

            @media (max-width: $mobile) {
                justify-content: flex-start;

                .doctor_patients_table__pagination {
                    padding-left: 50px !important;

                    span {
                        padding: 10px 15px;
                    }
                }
            }
        }
    }
}

.doctor_patients_table_status_tag {
    width: 100%;
    padding: 5px 4px 4px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 500;
    color: #000000;
    text-align: center;
}

.doctor_patients_table_status_tag--expired {
    background-color: #944f37;
    color: #ffffff;
}

.doctor_patients_table_status_tag--awaiting_paper {
    background-color: #3E4057;
    color: #ffffff;

}

.doctor_patients_table_status_tag--paper_received {
    background-color: #13ae7d;
    color: #ffffff;

}

@media screen and (max-width: $mobile) {
    .doctor_patients_table {
        min-width: 100vw;
    }
}