.footer_mobile {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: white;

  .footer_gmpc {
    img {
      max-width: 100px;
    }
  }

  .footer_mobile__content {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    color: $off-black;

    img {
      @extend .m-t-xxs;
    }

    p {
      font-weight: 400;
    }
  }
}