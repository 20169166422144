.custom_two_sided_modal {
  background: white;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  width: 50%;
  font-family: $sans-serif;

  &__header {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;

    font-family: $sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #000000;
    border-bottom: 1px solid #D5D5D5;

    &__close {
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    min-height: 500px;
    width: 100%;

    &__step_title {
      border-bottom: 2px solid #c0b9b9;
      width: 100%;
      font-size: 16px;
      font-family: $sans-serif;
      padding-bottom: 0.5rem;
    }

    &__step_explanation {
      padding: 1rem;
      background: #F0F4F1;
      font-size: 14px;
    }

    &__left {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 2rem;
      max-height: 500px;
      overflow-y: scroll;
    }

    &__right {
      width: 50%;

      .prescription_preview_container {
        width: 100%;
        height: 100%;

        iframe {
          height: 100%;
        }

        .lb-container {
          height: 100% !important;
          max-height: 500px;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem;
    border-top: 1px solid #D5D5D5;

    div {
      display: flex;
      justify-content: space-between;
      width: 70%;

      button {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}