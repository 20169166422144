.clinic_prescriptions_dashboard {
  background-color: #f6f6f6;
  display: flex;
  overflow-x: hidden;
  height: 100%;

  &__search {
    width: 90%;
    display: flex;
    justify-content: flex-end;

    &__holder {
      min-width: 300px;
      padding-right: 50px;
      margin-top: 20px;
    }
  }

  &__preview {
    /*max-width: 0px;*/
    width: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    background-color: #3e4057;


    &--show {
      width: 500px;
      overflow-y: scroll;

    }
  }

  &__table_holder {
    border-radius: 5px;
    margin: 0 auto;
    width: 100%;
    padding: 1rem;
    padding-top: 0px;
    overflow-x: scroll;

    // transition: all 1s ease;
    thead {
      z-index: 1;
      position: sticky;
      top: 0;
      background: #f6f6f6;
    }
  }

  &__table_empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f6f6f6;
    height: 500px;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 266px;

      p {
        margin: 19px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;

        text-align: center;

        color: #484848;
      }
    }
  }

  &__disclaimer-banner {
    padding: 24px 40px;
    margin-bottom: 6px;
    min-width: 1000px;
    background: #F0F4F2;
    display: flex;
    flex-direction: row;

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
      margin-right: 1rem;
    }

    &__close-button {
      cursor: pointer;
      margin-left: auto;
      margin-bottom: 20px;
    }

    &__red {
      padding: 24px 40px;
      margin-bottom: 6px;
      min-width: 1000px;
      display: flex;
      flex-direction: row;
      background: #ff8791;
      border: 1px solid grey;

      p {
        font-weight: 400;
      }
    }

    &__transparent {
      padding: 24px 40px;
      margin-top: 6px;
      margin-bottom: 6px;
      min-width: 1000px;
      display: flex;
      flex-direction: row;
      background: transparent;

      p {
        font-weight: 400;
      }
    }

    thead {
      position: sticky;
      top: 0;
      background: #f6f6f6;
    }
  }
}

@media screen and (max-width: $mobile) {
  .clinic_prescriptions_dashboard {
    &__holder {
      min-width: 100vh;
    }

    &__disclaimer-banner {
      &__red {
        padding: 12px 20px;
        min-width: 100vw;
        min-width: 100vw;

        p {
          font-size: 0.625rem;
        }
      }

      &__transparent {
        padding: 12px 20px;
        min-width: 100vw;
        min-width: 100vw;

        p {
          font-size: 0.625rem;
        }
      }
    }
  }
}