.change_password_card {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__head {
    @extend .m-b-m;

    padding-bottom: 14px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $light-grey;
  }

  &__body {
    @extend .m-b-xl;

    .label--caps--normal {
      @extend .p-b-xs;

      color: $dark-grey;
    }

    .p4 {
      color: $black;
      font-weight: 400;
    }
  }

  &__your_password {
    @extend .m-b-ss;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__change_link {
    display: none;
  }
}

@media screen and (max-width: $mobile) {
  .change_password_card {
    &__body {
      margin-bottom: $space-m;
    }

    &__change_link {
      display: block;

      .p4 {
        font-weight: 400;
      }
    }

    &__button {
      display: none;
    }
  }
}