.organisation-get-started {
  @extend .flex-center;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  min-height: 100%;

  &__container {
    @extend .p-h-s;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    @extend .m-t-xxl;
  }

  &__cta {
    height: 40px;

    button {
      @extend .m-t-s;
      border-radius: 4px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .order_cancelled {
    margin-top: $space-l;
    align-items: flex-start;
    height: auto;

    .order_cancelled__content {
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;

      .order_cancelled__title {
        width: 100%;
        text-align: left;
        align-items: flex-start;

        h1 {
          font-family: $serif;
          font-weight: bold;
          font-size: 2.25rem;
        }

        .p5 {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}