.promote_proto-prescription_modal {
  background: white;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  width: 50%;
  font-family: $sans-serif;

  &__header {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: auto;

    font-family: $sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #000000;
    border-bottom: 1px solid gray;

    &__close {
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    min-height: 500px;
    width: 100%;

    &__step_title {
      border-bottom: 2px solid #c0b9b9;
      width: 100%;
      font-size: 16px;
      font-family: $sans-serif;
      padding-bottom: 0.5rem;

      &__prescriber_name {
        font-family: $sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: #787878;
      }
    }

    &__step_explanation {
      padding: 1rem;
      background: #F0F4F1;
      font-size: 14px;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 28px;

        letter-spacing: 1px;

        color: $mid-grey;
      }

      &__heading {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;

        color: $off-black;
      }
    }

    &__step_contents {
      p {
        font-weight: normal;
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 14px;
      }

      ul {
        list-style: disc;
        margin-left: 15px;
      }
    }

    &__left {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 2rem;
      max-height: 500px;
      overflow-y: scroll;
    }

    &__right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(246, 246, 246, 1);

      .sidebar_iframe {
        height: 100%;
      }

      .reusable_drop_zone {
        height: 70%;
        width: 70%;
      }

      .prescription_preview_container {
        width: 100%;
        height: 100%;

        .lb-container {
          height: 100% !important;
          max-height: none;
        }
      }
    }

    &__or-component {
      font-weight: 800;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 35px 0;

      &__line {
        width: 50%;
        height: 0;
        border: 1px solid $light-grey;
      }

      p {
        color: $light-grey;
        margin: 5px;
      }
    }

    &__quick-upload {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 24px;
      gap: 16px;

      width: 100%;
      height: 224px;

      border: 1px solid $light-grey;
      border-radius: 10px;

      &__title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: black;

      }

      &__content {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: black;
      }

      &__cta {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;

        span {
          font-size: 14px;
          margin-left: 10px;
        }
      }
    }
  }

  &__confirm-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__heading {
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 28px;

      text-align: center;

      color: $purple;
    }

    &__text {
      margin: 48px 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;

      text-align: center;

      color: $off-black;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem;
    width: 98%;
    margin: auto;
    border-top: 1px solid gray;

    div {
      display: flex;
      justify-content: space-between;
      width: 70%;

      button {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}