.clinic_search_field {
    font-family: 'Poppins';
    width: 318px;
    height: 48px;
    display: flex;
    position: relative;
    background: #F7F7F7;

    border-radius: 5px;
    margin-left: auto;
    margin-right: 1rem;

    border: 1px solid $light-grey;

    &--focused {
        border: 2px solid #CCCCCC;
    }

    :hover {
        transition: 0.3s;
        background: #F2F2F2;
    }

    &__options {
        animation: swing-in-top-fwd .5s cubic-bezier(.175, .885, .32, 1.275) both;

        box-shadow: 0px 5px 12px -6px rgba(0, 0, 0, 1);
        border: 1px solid #CCCCCC;
        width: 316px;
        position: absolute;
        background: #F7F7F7;
        top: 49px;
        max-height: 600px;
        overflow-wrap: break-word;
        overflow-y: auto;
        overflow-x: hidden;
        @extend .shadow;

        &__section_title {
            padding: 0.5rem;
            color: #B3B3B3;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            text-transform: uppercase;

            hr {
                margin: auto;
                border: none;
                height: 1px;
                background-color: lightgray;

                &:hover {
                    background: lightgray;
                }
            }
        }

        &__option {
            border-radius: 0.5rem;
            margin: 2px;
            padding: 0.5rem;
            font-size: 14px;
            max-width: 318px;


            &_subtext {
                font-weight: "300";

                &:hover {
                    background: $green-light;
                    cursor: pointer;
                }
            }

            &:hover {
                background: $green-light;
                cursor: pointer;
            }
        }

        &__spinner {
            border-radius: 0.5rem;
            margin: 2px;
            padding: 0.5rem;
            font-size: 16px;
            max-width: 318px;

            display: flex;
            justify-content: center;
        }
    }

    label {
        width: 100%;
        display: flex;
        padding: 5px;
    }

    &__input {
        flex: 0.95;
        background-color: #F7F7F7;
        outline: none;
        border: none;
        padding-left: 0.5rem;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;

        :hover {
            background: #F2F2F2;
        }
    }

    :disabled {
        color: white;

        background-image: url(../../assets/images/greenBox.svg);
        background-repeat: no-repeat;
        background-position: left center;
        display: inline-block;
    }

    &__icon {
        flex: 0.05;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    /* ----------------------------------------------
 * Generated by Animista on 2022-6-22 11:4:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    @keyframes swing-in-top-fwd {
        0% {
            transform: rotateX(-100deg);
            transform-origin: top;
            opacity: 0
        }

        100% {
            transform: rotateX(0deg);
            transform-origin: top;
            opacity: 1
        }
    }

    /* ----------------------------------------------
 * Generated by Animista on 2023-9-14 14:22:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    /**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
    @keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}