.no_prescriptions {
  @extend .m-b-s;

  // 96 is header height
  // 73 is dash nav height,
  // 20 is something idk
  min-height: calc(100vh - 96px - 73px - 20px);
  height: auto;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 58px 24px 29px 24px;

  &--content {
    @extend .p-h-s;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 432px;

    &>p {
      @extend .m-b-s;
    }

    &>div {
      display: flex;
      justify-content: center;
      width: fit-content;
    }
  }

  &--banner {
    width: 100%;
    margin-top: 97px;
  }
}

.j-c-c {
  justify-content: center !important;
}