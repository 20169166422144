.status_sidebar {
  transition: all 0.3s ease-in-out;

  &__close {
    display: none;
  }

  &__title {
    @extend .p-v-s;

    padding-left: 24px;
    padding-right: 24px;
    z-index: 100;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $green-light;
    min-height: 68px;
  }

  &__content {
    @extend .p-t-ss;

    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;

    &__subtitle {
      .patient_name {
        color: $dark-grey;
      }

      span {
        text-transform: uppercase;
        letter-spacing: 0.1rem;
      }

      h3 {
        margin-bottom: 16px;
      }

      div {
        margin-bottom: 5px;
      }
    }
  }

  &__no_active {
    &>h3 {
      @extend .m-b-s;
    }

    &>p {
      @extend .m-b-ss;
    }

    &>div {
      width: 50%;

      &>div {
        @extend .m-b-xs;
      }
    }
  }

  &__steps {
    @extend .m-t-m;
    @extend .m-h-xs;
    @extend .p-h-xs;

    .step_container {
      .step_title {
        display: flex;

        &__circle {
          position: relative;
          display: flex;
          align-items: center;

          .unchecked_circle {
            position: absolute;
            transform: translateX(-50%);
          }

          .checked_circle {
            transform: translateX(-50%);
            position: absolute;
          }
        }

        &__text {
          padding-left: 35px;

          width: 90%;
        }
      }

      .border_left--green {
        border-left: 2px solid $green;
      }

      .border_left--light-green {
        border-left: 2px solid $green-lighter;
      }

      .border_left--grey {
        border-left: 2px solid $extra-light-grey;
      }

      .step_text {
        padding-left: 35px;
        padding-top: 15px;
        padding-bottom: 34px;
        line-height: 24px;
        display: flex;

        &__empty {
          height: 100%;
        }

        p {
          font-weight: 300;
          width: 90%;
        }

        span {
          font-weight: 300;
        }
      }
    }
  }

  &__actions {
    width: 100%;
    position: absolute;
    bottom: 0;

    &>div {
      padding: 2rem 1rem;
      justify-content: space-evenly;
      display: flex;
    }
  }
}

@media screen and (max-width: $mobile) {
  .status_sidebar {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;

    &__close {
      display: block;
    }

    &__title {
      padding-left: $space-s;
      padding-right: $space-s;
      background-color: transparent;
      border-bottom: 1px solid $light-grey;
    }

    &__content {
      padding-left: $space-s;
    }

    &__steps {
      padding-left: $space-s;
    }
  }

  .status_sidebar--hide {
    left: -100%;
  }
}