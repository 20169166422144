.order_details {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 18px;
    padding: 28px 25px 84px 24px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
    border-radius: 4px;

    &__count {
        text-align: right;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 500;
    }

    h4 {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 500;
    }

    &__information {
        display: flex;

        &__column {
            display: flex;
            flex-direction: column;

            &:nth-of-type(2) {
                margin-left: 4rem;
            }

            &__row {
                font-family: "Poppins", sans-serif;
                margin-top: 5px;
                font-size: 12px;
                letter-spacing: 1.2px;

                &__title {
                    font-weight: 500;
                }
            }
        }
    }
}

@media (max-width: $mobile) {
    .order_details {
        margin-left: 0px;
        margin-right: 0px;

        &__information {
            flex-direction: column;

            &__column {
                &:nth-of-type(2) {
                    margin-left: 0rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .order_details {
        margin-left: 20px;
        margin-right: 20px;
    }
}