.text-input-container {
  width: 100%;
  position: relative;
  display: inline-block;

  // padding-top: 16px;
  // padding-bottom: 24px;

  transition: border-bottom 0 $form-transition-duration ease-out;

  .label-top {
    transform-origin: 0 0;
    transform: scale(0.7);
    // font-size: 10px;
    top: 8px;
    left: 12px;
  }

  .label-disabled {
    color: $light-grey;
  }

  &--permanently_disabled {
    label {
      display: none !important;
    }

    input {
      border-bottom: none !important;
      padding: 19px 12px 19px 12px !important;
      color: #000;
    }

    .animated-border--black {
      display: none !important;
    }

    .animated-border--red {
      display: none !important;
    }

    .animated-border--blue {
      display: none !important;
    }
  }

  label {
    @extend .label;
    @extend .transition;

    transform-origin: 0 0;
    transform: scale(1);
    display: block;
    position: absolute;
    top: 20px;
    left: $space-xs;
    color: $mid-grey;
    pointer-events: none;

    //override
    font-weight: 500 !important;

    &:hover {
      cursor: text;
    }
  }

  .text-input--error {
    margin-top: 5px;
    // position: absolute;
    color: $error;
    font-size: 10px;
    // in case a parent has text align center
    text-align: left !important;
  }

  select,
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="password"],
  input[type="tel"] {
    @extend .p5--semi-bold;
    @extend .transition;

    padding: 28px 12px 10px 12px;
    width: 100%;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    background: $extra-light-grey;
    border-bottom: 2px solid $mid-grey;
    outline: none;
    z-index: 99;
    font-family: $sans-serif;

    // appearance: none;
    // -moz-appearance: none;
    // -webkit-appearance: none;

    // override
    line-height: 0px !important;

    &::placeholder {
      color: $light-grey;
    }

    &:focus~label {
      @extend .label-top;
    }

    &:disabled {
      border-bottom: 1px solid $light-grey;
    }

    &:focus~.animated-border--blue {
      width: 100%;
    }

    &:focus~.animated-border--red {
      width: 100%;
    }

    &:focus~.animated-border--black {
      width: 100%;
    }

    &:focus {
      background: #e8e8e8;
      border-bottom: 2px solid transparent;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

// select {
//   height: 61px !important;
// }


.text-input-container:focus-within {
  border-bottom: none;
}

.animated-border--blue.animated-border--show {
  width: 100%;
}

.animated-border--red.animated-border--show {
  width: 100%;
}

.form-spacing {
  margin-bottom: 24px;
}

// select option
.option {
  background-color: white;
  font-family: $sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.transition {
  -webkit-transition: top 0.3s ease-in-out, left 0.3s ease-in-out,
    transform 0.3s ease-out;
  -moz-transition: top 0.3s ease-in-out, left 0.3s ease-in-out,
    transform 0.3s ease-out;
  -ms-transition: top 0.3s ease-in-out, left 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  -o-transition: top 0.3s ease-in-out, left 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.text_input_title {
  font-family: $sans-serif;
  color: #787878;
  font-weight: 500;
  font-size: 14px;
}