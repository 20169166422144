.account_footer {
  @extend .p-t-m;

  background: white;
  padding-top: 13px;

  &__row {
    padding: 40px 40px 17px;
    display: flex;

    p {
      color: $off-black;
    }

    .account_footer__row__col .footer_gmpc {
      img {
        @extend .m-t-xxxl;
      }
    }

    .account_footer__row__col.footer_gmpc {
      img {
        max-width: 100px;
      }
    }

    .label--semi-bold {
      margin-top: 0;
      font-size: 0.625rem;
      font-weight: 600;
    }

    &__col {
      &>p {
        margin-top: 10px;
      }
    }

    &__col_bottom-right {
      justify-content: flex-end;
      display: flex;
      width: 50%;

      .m-r-10 {
        margin-right: 10%;
      }
    }

    &__col--right {
      // text-align: right;
      margin-left: auto;
      margin-bottom: 25px;

      &>p {
        color: $dark-grey;
      }

      &>img {
        margin-right: 25px;
      }
    }
  }

  .footer_images {
    margin-bottom: 55px;

    img {
      margin-left: 24px;
    }

    p {
      color: $mid-grey;
      font-weight: 500;

      span {
        font-size: inherit;
        font-weight: 500;
      }

      a {
        font-size: inherit;
      }
    }
  }

  .footer_gmpc {
    p {
      color: $mid-grey;
      margin-top: 34px;
    }
  }

  .border-bottom {
    border-bottom: 1.5px solid $light-grey;
    padding: 0px 40px 36px 40px;
    align-items: center;
    margin-top: -6px;
  }
}

@media screen and (max-width: $mobile) {
  .account_footer {
    display: none;
  }
}