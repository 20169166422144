.privacy_policy_table {
    width: 100%;
    overflow-x: scroll;
    font-family: $sans-serif;

    &__container {
        min-width: 450px;

        &__header {
            display: flex;

            &__cell {
                font-weight: 500;
                background-color: #13ae7d;
                padding: 15px 22px;

                &:first-of-type {
                    border-left: 1px solid #d5d5d5;
                }

                border-right: 1px solid #d5d5d5;
                border-top: 1px solid #d5d5d5;
                border-bottom: 1px solid #d5d5d5;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__row {
            display: flex;

            &__cell {
                padding: 1rem;
                border-bottom: 1px solid #13ae7d;
                border-right: 1px solid #13ae7d;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                div {
                    font-size: 14px;
                }

                /* word-break: break-all; */
                hyphens: auto;

                &:first-of-type {
                    border-left: 1px solid #13ae7d;
                }

                ul {
                    list-style: square;
                    margin-left: 0.5rem;

                    li {
                        text-overflow: ellipsis;
                        word-break: break-word;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}