.before_we_start_header {
  @extend .p-v-m;
  @extend .p-h-m;

  position: absolute;
  top: 0;
  height: 96px;
  width: 100vw;
  background: transparent;
  z-index: 100000;

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    align-items: center;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .close_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $off-black;
      height: 20px;
    }

    .close_icon--white {
      @extend .close_icon;

      display: none;
    }

    .close_icon--black {
      @extend .close_icon;

      display: block;
    }

    p {
      padding-right: 16px;
      color: $off-black;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $mobile) {
  .before_we_start_header {
    padding: 0;
    padding-left: $space-s;
    padding-right: $space-s;
    height: 72px;
    align-items: flex-start;

    &__content {
      padding-top: $space-s;
      position: relative;
      z-index: 100;
      width: 100%;
    }

    &__close {
      .close_icon--white {
        display: block;
      }

      .close_icon--black {
        display: none;
      }

      &>p {
        display: none;
      }
    }
  }
}