.primary_cta {
  @extend .p-h-ss;
  @extend .p5--semi-bold;

  border: none;
  border-radius: 28px;
  outline: none;
  width: 100%;
  height: 100%;

  &--fit_content {
    width: fit-content;
    min-height: 2.5rem;
  }

  &--squared {
    border-radius: 4px;
  }

  transition: 0.3s;

  &:disabled {
    background: $light-grey;
    color: white;
  }
}

.primary_cta--blue {
  @extend .primary_cta;

  background: $blue;
  color: white;

  &:not(:disabled) {

    &:active,
    &:focus,
    &:hover {
      cursor: pointer;
      background: $purple;
      color: white;
    }
  }
}

.primary_cta--dark_blue {
  @extend .primary_cta;
  color: white;
  background: #3E4057;
  transition: 0.3s;

  &:not(:disabled) {

    &:active,
    &:focus,
    &:hover {
      cursor: pointer;
      background: #666c93;
      color: white;
    }
  }
}

.primary_cta--white_grey {
  @extend .primary_cta;
  color: black;
  background: white;
  transition: 0.3s;

  &:not(:disabled) {

    &:active,
    &:focus,
    &:hover {
      cursor: pointer;
      background: #666c93;
      color: white;
    }
  }
}

.primary_cta--blue.disabled_button {
  background: $light-grey;
  pointer-events: none;

  &:active,
  &:focus,
  &:hover {
    cursor: auto;
    background: $light-grey;
  }
}

.primary_cta--white {
  @extend .primary_cta;

  background: white;
  color: black;
  border: 1px solid $light-grey;

  &:not(:disabled) {

    &:active,
    &:focus,
    &:hover {
      background: $light-grey;
      cursor: pointer;
      color: black;
    }
  }
}

.primary_cta--form {
  @extend .primary_cta;

  background: $orange;
  color: white;
  border: none;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border-radius: 4px !important;

  &:not(:disabled) {

    &:active,
    &:focus,
    &:hover {
      background: $orange-dark;
      cursor: pointer;
      color: white;
    }
  }
}

.primary_cta--orange {
  @extend .primary_cta;

  background: $orange;
  color: white;

  &:not(:disabled) {

    &:active,
    &:focus,
    &:hover {
      cursor: pointer;
      background: $orange-dark;
      color: white;
    }
  }
}

.primary_cta--orange.disabled_button {
  background: $beige;
  color: $mid-grey;
  pointer-events: none;

  &:active,
  &:focus,
  &:hover {
    cursor: auto;
    background: $beige;
    color: $mid-grey;
  }
}

@media screen and (max-width: $mobile) {
  .primary_cta--blue.disabled_button {
    background: $blue;
    pointer-events: auto;

    &:not(:disabled) {

      &:active,
      &:focus,
      &:hover {
        cursor: pointer;
        background: $purple;
        color: white;
      }
    }
  }

  .primary_cta--orange.disabled_button {
    background: $beige;
    pointer-events: auto;

    &:not(:disabled) {

      &:active,
      &:focus,
      &:hover {
        cursor: pointer;
        background: $beige;
        color: white;
      }
    }
  }
}