.my_account_popup {
  position: relative;
  z-index: 10;

  &__header {
    display: flex;
    align-items: center;
    margin-right: 0 !important;
    cursor: pointer;

    &>span {
      @extend .p-l-xs;
    }
  }

  .up-arrow {
    position: absolute;
    width: 0;
    height: 0;
    right: -5%;
    top: 115%;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid $green-light;
    z-index: 2;
  }

  &__content {
    position: absolute;
    right: -20px;
    top: 150%;
    display: flex;
    flex-direction: column;
    background: white;
    width: 300px;
    border-radius: 4px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    color: $off-black;

    p {
      @extend .label;

      color: $off-black;
    }

    a {
      color: $off-black;
      text-decoration: none;
    }

    .my_account_popup__head {
      @extend .p-v-s;
      @extend .p-l-s;

      display: flex;
      flex-direction: column;
      background: $green-light;
      color: $black;
      cursor: auto;

      h1 {
        @extend .p-b-xxs;
      }

      p {
        color: $off-black;
      }

      &__title {
        font-family: $sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: $off-black;
      }

      &__large_text {
        font-family: $sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        color: rgba(0, 0, 0, 1);
        opacity: 0.5;
      }

      &__small_text {
        font-family: $sans-serif;
        font-style: normal;
        font-size: 12px;
        line-height: 24px;
        color: rgba(0, 0, 0, 1);
        opacity: 0.75;
      }
    }

    .my_account_popup__body {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-evenly;
      color: $off-black;

      .my_account_popup__item {
        @extend .p6;

        padding: 13px 16px;
        line-height: 18px;
        color: $off-black;
        cursor: pointer;
        border-bottom: 2px solid $extra-light-grey;

        &:hover {
          transition: 0.2s;
          background: $extra-light-grey;
        }

        p {
          @extend .p6--semi-bold;
        }
      }

      .my_account_popup__learn {
        padding: 13px 16px;

        p {
          @extend .p6;
        }

        div {
          @extend .p-r-xs;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;

        color: $off-black;
        cursor: pointer;
        border-bottom: 2px solid $extra-light-grey;

        &:hover {
          transition: 0.2s;
          background: $extra-light-grey;
        }
      }
    }
  }
}