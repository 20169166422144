.upload_prescription {
  @extend .m-t-xl;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  display: flex;
  justify-content: center;

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 660px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-t-s;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .label--caps--normal {
      @extend .m-b-s;
    }

    h1 {
      @extend .m-b-ss;
    }

    .p5 {
      @extend .m-b-mm;

      width: 80%;
    }
  }

  &__carousel {
    @extend .m-b-l;

    width: 100%;
  }

  &__uploaded {
    width: 75%;

    &>div {
      @extend .m-b-l;
    }
  }

  &__submit_prescription {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__radio_button {
    @extend .m-b-ss;

    width: 60%;
  }

  &__tertiary_button {
    @extend .m-b-m;
    @extend .w-252;
    @extend .h-48;
  }

  &__primary_button {
    @extend .w-240;
    @extend .h-48;
  }

  &__terms {
    @extend .m-b-m;

    width: 70%;
  }

  &__error {
    @extend .m-t-s;

    color: $error;
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: $tablet) {
  .upload_prescription {
    &__content {
      padding-left: $space-s;
      padding-right: $space-s;

      width: 100%;
    }
  }
}

@media screen and (max-width: $mobile) {
  .upload_prescription {
    margin-top: $space-l;

    &__content {
      width: 100%;
      align-items: flex-start;
      padding-left: $space-s;
      padding-right: $space-s;
    }

    &__title {
      align-items: flex-start;
      text-align: left;
      width: 100%;

      h1 {
        font-weight: bold;
        font-family: $serif;
        font-size: 2.25rem;
      }
    }

    &__carousel {
      margin-bottom: $space-s;
    }

    &__primary_button {
      width: 100%;
    }

    &__tertiary_button {
      width: 100%;
    }

    &__uploaded {
      width: 100%;
    }

    &__submit_prescription {
      width: 100%;
    }

    &__radio_button {
      width: 100%;
    }

    &__terms {
      width: 100%;
      margin-bottom: $space-ss;
    }

    &__buttons {
      flex-direction: column;
      width: 100%;

      &>div {
        margin-bottom: $space-s;
        width: 100%;
      }
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}