.repeat_prescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    position: relative;
    padding: 0 20px;

    &--content {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;

        .content_heading {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 40px;
            width: 100%;
            max-width: 394px;


            h1 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                line-height: 26px;
                margin-top: 40px;
            }

            &--noTmcc {
                max-width: 547px;
                width: 100%;
                text-align: center;
                margin-bottom: 45px;

                h1 {
                    font-family: 'Frank Ruhl Libre';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 42px;
                    line-height: 54px;
                    margin: 16px 0 32px 0;
                }

                span {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 24px;
                    text-transform: uppercase;
                }

                p {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 26px;
                }
            }

        }

        .content_text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            width: 100%;
            max-width: 394px;
            text-align: center;

            &--noTmcc {
                width: 100%;
                max-width: 547px;

                .divider {
                    width: 100%;
                    height: 1px;
                    background-color: #D5D5D5;
                    margin: 14px 0 32px 0;
                }

                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 26px;
                }

                span {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    color: #787878;
                    margin-bottom: 4px;
                }

                p {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 26px;
                    margin-bottom: 18px;
                }
            }

            &--tmcc {
                width: 100%;
                max-width: 611px;

                p {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 44px;

                    span {
                        min-width: 40px;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 22px;
                        background-color: $green-light;
                        text-align: center;
                        font-weight: 600;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-size: 22px;
                        line-height: 22px;
                        margin-right: 20px;
                    }
                }
            }
        }

        .content_button {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            max-width: 233px;
            height: 48px;
            margin-top: 70px;
        }
    }

    .spinner_loader {
        display: flex;
        flex-direction: column;
        margin-top: 45px;
        align-items: center;

        p {
            margin-bottom: 20px;
        }
    }
}

@media (max-width: $mobile) {
    .repeat_prescription {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100%;
        margin-top: 0;
        position: relative;
        padding: 0 20px;
        overflow: hidden;

        &--content {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 130px;
            padding-bottom: 20%;

            .content_heading {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-bottom: 40px;
                width: 100%;
                max-width: 394px;
                margin-top: 100px;


                h1 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 26px;
                    margin-top: 40px;
                }

                &--noTmcc {
                    max-width: 547px;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 45px;

                    h1 {
                        font-family: 'Frank Ruhl Libre';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 42px;
                        line-height: 54px;
                        margin: 16px 0 32px 0;
                    }

                    span {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 24px;
                        text-transform: uppercase;
                    }

                    p {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 26px;
                    }
                }

            }

            .content_button {
                margin-bottom: 40px;
            }
        }
    }

    .spinner_loader {
        width: 100vw;
        display: flex;
        flex-direction: column;
        height: 100vh;
        align-items: center;
        overflow: hidden;
        justify-content: center;
        margin-top: 45px;

        p {
            margin-bottom: 20px;
        }

        svg {
            height: 50px !important;
        }
    }
}