.order__products {
  @extend .m-b-xs;

  background: white;
  width: 100% !important;

  hr {
    width: 90%;
    height: 1px;
    margin: 0 auto;
    opacity: 0.3;
  }

}

.order__is_void {
  @extend .m-b-s;
  @extend .m-h-s;
  @extend .p-v-xs;
  @extend .p-h-xxs;

  width: auto;
  background: $extra-light-grey;
  display: flex;
  justify-content: center;
  align-items: center;

  &>div:first-child {
    @extend .m-r-xs;
  }
}

@media screen and (max-width: $mobile) {
  .order__products {
    padding-bottom: 10px;
    margin-bottom: 24px;
  }
}