.contact_numbers {
  @extend .flex-center;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 474px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-ss;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .label--caps--normal {
      @extend .m-b-s;
    }

    h1 {
      @extend .m-b-ss;
    }

    .p5 {
      text-align: left;
      color: $off-black;
      width: 90%;
    }
  }

  &__form {
    @extend .m-b-ss;

    width: 100%;

    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__button {
    @extend .m-b-mm;
    @extend .w-240;
    @extend .h-48;
  }
}

@media screen and (max-width: $mobile) {
  .contact_numbers {
    margin-top: $space-l;
    align-items: flex-start;

    .contact_numbers__content {
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;

      .contact_numbers__title {
        align-items: flex-start;
        text-align: left;
        width: 100%;

        h1 {
          margin-bottom: $space-s;
          font-family: $serif;
          font-weight: bold;
          font-size: 2.25rem;
        }

        p {
          width: 100%;
        }
      }

      .contact_numbers__form {
        width: 100%;
        flex-direction: column;
      }

      .contact_numbers__button {
        width: 100%;
      }
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}