.mobile_upload_confirmation {
  @extend .m-t-xl;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  display: flex;
  justify-content: center;

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__uploaded {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__uploaded-checkmark {
    width: 60px;
    height: 60px;
  }

  &__uploaded-text {
    @extend .m-t-s;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #484848;
  }

  &__title {
    @extend .m-t-s;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 20px;
      color: $dark-grey;
    }
  }

  &__what_happens_next {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h2 {
      @extend .m-b-mm;

      text-align: center;
    }
  }

  &__what_happens_next--mobile {
    display: none;
  }

  &__cards {
    @extend .m-b-m;

    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
  }

  &__important {
    @extend .m-b-s;
    @extend .m-t-s;
    @extend .p-s;

    width: 70%;
    display: flex;
    flex-direction: column;
    background-color: $extra-light-grey;

    .mobile_upload_confirmation__red_button {
      @extend .m-b-s;

      display: flex;
      align-items: center;

      p {
        @extend .p-l-xs;
      }
    }

    .mobile_upload_confirmation__please_make_sure {
      ul {
        display: block;
        list-style-type: disc;
        margin: 1em 0 16px;
        padding-left: 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        li {
          font-family: 'Poppins';
          margin-bottom: 16px;
        }
      }
    }

    .mobile_upload_confirmation__address {
      @extend .p-t-s;

      p {
        @extend .p-b-xxs;
      }
    }
  }

  &__button {
    @extend .w-240;
    @extend .h-48;
  }

  &__buttons {
    @extend .m-b-l;

    display: flex;
    justify-content: center;

    &>div {
      @extend .w-240;
      @extend .h-48;
      @extend .p-r-s;
    }
  }
}

@media screen and (max-width: $tablet) {
  .mobile_upload_confirmation {

    &__buttons {
      width: 100%;
      flex-direction: column;

      &>div {
        margin-bottom: $space-s;
        padding-right: 0px;
        width: 100%;
      }
    }

    &__what_happens_next {
      display: none;
    }

    &__what_happens_next--mobile {
      margin-bottom: $space-xl;
      width: 100%;
      display: block;
    }
  }
}

@media screen and (max-width: $mobile) {
  .mobile_upload_confirmation {
    margin-top: $space-l;

    &__content {
      width: 100%;
      align-items: flex-start;
      padding-left: $space-s;
      padding-right: $space-s;
    }

    &__button {
      margin-bottom: $space-s;
      width: 100%;
    }

    &__what_happens_next {
      display: none;
    }

    &__what_happens_next--mobile {
      display: block;
      margin-bottom: $space-m;
    }

    &__important {
      width: 100%;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}