.mobile_product {
  @extend .p-h-s;
  @extend .p-t-m;

  display: none;

  &__title {
    @extend .m-v-s;
  }

  &__button {
    @extend .p-v-xs;
    @extend .m-t-s;
    @extend .m-b-m;

    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $light-grey;

    .p5--semi--bold {
      margin-right: 8px;
    }
  }

  &__button--active {
    background: $light-grey;
  }
}

@media screen and (max-width: $mobile) {
  .mobile_product {
    display: block;
  }
}