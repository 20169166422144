.patient_confirm_dob_patient_id {
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  max-width: 500px;
  min-height: calc(100vh - 96px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &__header {
    font-family: $sans-serif;
    text-align: center;
    margin-bottom: 2rem;

    &__small_title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      text-transform: uppercase;
    }

    &__title {
      @extend .m-v-s;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 54px;
      font-family: $serif;
    }

    &__description {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
    }
  }

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 625px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-m;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &>h1 {
      @extend .m-t-s;
    }

    &>p.p5 {
      @extend .m-t-ss;
      text-align: left;
    }
  }

  &__form {
    @extend .m-b-m;

    width: 85%;

    &__input_name {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $light-grey;
      font-family: $sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
    }

    &__hint {
      font-family: $sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 1rem
    }
  }

  &__error {
    @extend .p-b-m;

    color: $error;
  }

  &__buttons {
    @extend .m-b-l;

    display: flex;
    justify-content: center;

    &>div {
      @extend .w-240;
      @extend .h-48;
      @extend .p-r-s;
    }
  }

  &__button {
    @extend .m-b-l;
    @extend .w-240;
    @extend .h-48;
  }
}

@media screen and (max-width: $tablet) {
  .patient_confirm_dob_patient_id {
    &__buttons {
      width: 100%;
      flex-direction: column;

      &>div {
        margin-bottom: $space-xxs;
        padding-right: 0px;
        padding-left: 0;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .patient_confirm_dob_patient_id {
    margin-top: $space-l;
    align-items: flex-start;
    height: auto;

    &__content {
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;
    }

    &__title {
      align-items: flex-start;
      text-align: left;
      width: 100%;

      &>h1 {
        font-family: $serif;
        font-weight: bold;
        font-size: 2.25rem;
      }
    }

    &__form {
      width: 100%;
    }

    &__button {
      width: 100%;

      &>div {
        width: 100%;
      }
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}