.selected_filter_remove_buttons_container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 700px;
  margin-left: 1rem;

  &::-webkit-scrollbar {
    height: 2px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    cursor: pointer;
  }
}

@media (max-width: 1800px) {
  .selected_filter_remove_buttons_container {
    overflow: scroll;
    overflow-y: hidden;
    max-width: 700px;
  }
}

@media (max-width: 1700px) {
  .selected_filter_remove_buttons_container {
    overflow: scroll;
    overflow-y: hidden;
    max-width: 650px;
  }
}

@media (max-width: 1500px) {
  .selected_filter_remove_buttons_container {
    overflow: scroll;
    overflow-y: hidden;
  }
}

@media (max-width: 1300px) {
  .selected_filter_remove_buttons_container {
    max-width: 500px;
  }
}

@media (max-width: 1100px) {
  .selected_filter_remove_buttons_container {
    display: none;
  }
}