.selected_filter_remove_button {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    background: #B3B3B3;
    border-radius: 27px;
    margin-left: 1rem;

    &:hover {
        background: #787878;
    }

    &__status {
        font-style: normal;
        white-space: nowrap;
        font-family: $sans-serif;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        color: white;
        margin-right: 2px;
    }

    &__text {
        font-style: normal;
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-weight: 600;
        font-family: $sans-serif;
        font-size: 10px;
        line-height: 15px;
        color: white;
        margin-right: 3px;
    }

    &__close {
        font-family: $sans-serif;
        color: white;
        white-space: nowrap;
        font-size: 10px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        margin-left: 1rem;
        cursor: pointer;
    }
}