.start_live_chat {
  padding: 0 23px;

  .start_live_chat__content {
    .start_live_chat__question {
      @extend .m-b-m;

      display: flex;
      justify-content: flex-start;
      align-items: center;

      cursor: pointer;

      p {
        @extend .p-l-xs;
        @extend .p-r-xs;

        font-weight: 400;
        color: $dark-grey;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .start_live_chat {
    .start_live_chat__content {
      .start_live_chat__question {
        justify-content: center;
      }
    }
  }
}