.confirm_details {
  @extend .m-t-xl;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  display: flex;
  justify-content: center;

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 546px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  &__title {
    @extend .m-b-xl;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &>p {
      @extend .m-b-s;
    }
  }

  &__cards {
    // @extend .m-b-l;

    width: 100%;
    display: flex;
    flex-direction: column;

    .your_details_card {
      @extend .m-b-l;
    }

    p.p4--semi-bold {
      font-size: 1.125rem;
      font-weight: normal;
    }
  }

  &__label {
    @extend .m-t-s;
  }

  &__clinic_details {
    @extend .m-t-m;
  }

  &__button {
    @extend .m-b-l;
    @extend .w-240;
    @extend .h-48;

    display: flex;
    justify-content: center;
  }

  &__buttons {
    @extend .m-b-l;
    width: 100%;

    display: flex;
    justify-content: space-evenly;

    &>div {
      @extend .w-240;
      @extend .h-48;
    }
  }

  &__error {
    @extend .m-b-s;

    margin-top: -25px;
    color: $error;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: $tablet) {
  .confirm_details {
    &__buttons {
      flex-direction: column;

      &>div {
        margin-bottom: $space-s;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .confirm_details {
    margin-top: $space-l;

    &__content {
      width: 100%;
      align-items: flex-start;
      padding-left: $space-s;
      padding-right: $space-s;
    }

    &__title {
      align-items: flex-start;
      text-align: left;
      width: 100%;

      &>h1 {
        font-family: $serif;
        font-weight: bold;
        font-size: 2.25rem;
      }
    }

    // &__cards {
    //   margin-bottom: $space-m;
    // }

    &__button {
      width: 100%;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}