.main_header {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96px;
  border-bottom: 1px solid $light-grey;
  user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ */

  &__logo {
    margin-right: 53px;
    cursor: pointer;
  }

  &__content {
    @extend .m-h-auto;
    @extend .p-h-m;

    width: 100%;
    display: flex;

    align-items: center;

    a {
      color: $off-black;
      text-decoration: none;
    }

    ul {
      width: 100%;
      display: flex;
      align-items: center;
      list-style: none;

      li {
        @extend .label;

        margin-right: 32px;
        user-select: none;
        position: relative;
        transition: all 0.35s ease;
        border-bottom: 2px solid transparent;
      }

      .active {
        border-bottom: 2px solid $green;
      }
    }
  }

  &__upload {
    margin-left: auto;
    margin-right: 0;
  }

  &__nav {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__my_account {
    cursor: pointer;
  }
}

.main_header.main_header--purple {
  background: $purple;
  color: white;
  border-bottom: none;

  a {
    color: white;
  }
}

.main_header.main_header--green {
  background: $green-light;
  border-bottom: none;
}

@media screen and (max-width: $mobile) {
  .main_header {
    display: none;
  }
}