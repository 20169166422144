.credit_card_form {
  display: flex;
  border-bottom: 1px solid #D5D5D5;
  padding-bottom: 1rem;

  &__number {
    flex: 0.5;
    margin-right: 5px;
  }

  &__date {
    flex: 0.3;
    margin-right: 5px;
  }

  &__cvc {
    flex: 0.2;
  }

  h5 {
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    font-family: $sans-serif;
  }
}

@media (max-width: 325px) {
  .credit_card_form {
    h5 {
      font-size: 14px;
    }
  }
}