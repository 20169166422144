.thank_you_for_confirming {
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    max-width: 500px;
    min-height: calc(100vh - 96px);

    &__content {
        &__header {
            font-family: $sans-serif;
            text-align: center;
            margin-bottom: 2rem;

            &__small_title {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 24px;
                text-transform: uppercase;
            }

            &__title {
                @extend .m-v-s;
                font-style: normal;
                font-weight: bold;
                font-size: 42px;
                line-height: 54px;
                font-family: $serif;
            }

            &__description {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 26px;
            }
        }

        ;

        .changing_password__form {
            width: 100%;
        }

        &__details_container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            flex-direction: column;
            margin-top: 3rem;

            &__detail {
                display: flex;
                width: 100%;
                flex-direction: column;
                margin-bottom: 2rem;

                &__header {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #D5D5D5;
                    padding-bottom: 1rem;

                    &__title {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 26px;
                        font-family: $sans-serif;
                    }

                    &__edit {
                        text-transform: uppercase;
                        font-size: 10px;
                        font-weight: 500;
                        font-style: normal;
                        font-family: $sans-serif;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                &__content {
                    padding-top: 1rem;
                    font-family: $sans-serif;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 26px;
                }
            }
        }

        &__buttons {
            margin-top: 5rem;
            margin-bottom: 2rem;
            display: flex;
            justify-content: center;

            button {
                height: 46px;
                width: 240px;
            }
        }
    }
}

@media (max-width: $mobile) {
    .thank_you_for_confirming {
        &__content {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}