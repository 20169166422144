.processing_order {
  @extend .p-b-s;
  @extend .m-b-xs;

  line-height: 26px;
  background: white;

  &__header {

    &>p.p5 {
      @extend .m-b-s;

      max-width: 552px;
      padding: 0 23px;
    }
  }

  &__header__top {
    padding: 31px 23px 30px;
    margin-bottom: 31px;

    &__tracked {
      padding: 31px 23px 30px;
      margin-bottom: 31px;
      transition: background 0.3s ease;
      background: $green-light;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 23px;

    user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10+ */

    &>div {
      display: flex;
    }
  }

  &__details_btn {
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
      @extend .m-r-xxs;
    }
  }

  &__body {
    @extend .p-h-s;

    max-height: 0;
    height: auto;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    border-top: 1px solid white;

    &>h3 {
      @extend .p-b-xs;
    }

    &>.p5 {
      @extend .p-r-s;
      @extend .p-b-m;

      max-width: 654px;
    }
  }

  &__body--show {
    @extend .p-t-ss;
    @extend .m-t-m;

    border-top: 1px solid $extra-light-grey;
    max-height: 10000px;
  }

  &__item {
    display: flex;
    align-items: flex-start;

    &__text {
      @extend .m-b-ss;
      @extend .m-l-s;
      @extend .p-r-s;

      width: 576px;

      .p3--semi-bold {
        @extend .p-b-xs;
      }
    }
  }

  &__banner {
    @extend .p-t-s;
  }
}

.number_image {
  position: relative;

  &>div {
    position: absolute;
  }

  &>span {
    position: absolute;
    transform: translate(-50%, -60%);

    top: 50%;
    left: 50%;
    z-index: 10;
    font-weight: 500;
    font-size: 1.125rem;
  }
}

@media screen and (max-width: $mobile) {
  .processing_order {
    .product_details--row {
      display: flex;
      flex-direction: column;
    }

    &__header__top {
      .h5-a--serif {
        font-size: 36px;
        line-height: 46px;
      }
    }

    &__buttons {
      flex-direction: column;
      width: 100%;

      &__separator {
        display: none;
      }

      &__left {
        width: 100%;

        &>div {
          width: 100%;
        }
      }

      &__right {
        flex-direction: column-reverse;
        text-align: center;

        width: 100%;

        &>.quaternary_cta {
          margin: $space-m auto $space-s auto;
          width: fit-content;
        }
      }
    }

    &__details_btn {
      padding: $space-xs 0;
      margin-top: $space-s;
      border: 1px solid $light-grey;
      justify-content: center;
      border-radius: 2px;
    }

    &__details_btn--active {
      background: $extra-light-grey;
    }
  }
}