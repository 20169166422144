.are_these_details_correct_button {
    margin-left: 23px;
    margin-bottom: 1rem;

    span {
        display: flex;
    }

    p {
        color: $dark-grey;
    }
}

@media screen and (max-width: $mobile) {
    .are_these_details_correct_button {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
}