.profile {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &__banner {
    @extend .p-t-xxl;

    color: $black;
    width: 100%;
    background: $green-light;
    display: flex;
    justify-content: center;

    &>div {
      @extend .p-h-s;

      width: 546px;

      h1 {
        @extend .p-b-ss;
      }

      p {
        @extend .p-b-xxl;

        color: $off-black;
      }
    }
  }

  &__body {
    @extend .m-t-xl;

    display: flex;
    justify-content: center;

    width: 100%;
  }

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 546px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  &__cards {
    width: 100%;
  }

  &__carer_details {
    @extend .m-b-l;
  }

  &__labels {
    @extend .m-t-ss;
  }

  &__buttons {
    @extend .m-b-xl;
    @extend .m-t-m;

    width: 100%;
    display: flex;
    justify-content: space-evenly;

    &>div {
      @extend .w-240;
      @extend .h-48;
    }
  }

  &__footer {
    border-top: 4px solid $extra-light-grey;
  }
}

@media screen and (max-width: $tablet) {
  .profile {
    &__buttons {
      flex-direction: column;
      margin-bottom: $space-m;

      &>div {
        margin-bottom: $space-s;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .profile {
    &__body {
      margin-top: $space-l;
      width: 100%;
    }

    &__buttons {
      margin-bottom: $space-m;
      display: flex;
      flex-direction: column;

      div:first-child {
        margin-bottom: $space-s;
        width: 100%;
      }

      div:last-child {
        width: 100%;
      }
    }

    &__footer {
      border-top: 16px solid $extra-light-grey;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}