.repeat_prescription {
  @extend .m-t-xl;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  display: flex;
  justify-content: center;

  &__content {
    @extend .p-h-s;

    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend .m-b-l;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      @extend .m-t-s;
      @extend .m-b-ss;
    }

    .p5 {
      width: 90%;
      text-align: left;
    }
  }

  &__doctor_details {
    @extend .m-b-m;

    width: 100%;
  }

  &__test {
    @extend .m-b-ss;
    @extend .p-b-xs;

    border-bottom: 1px solid $light-grey;
  }

  &__buttons {
    @extend .m-b-xl;

    width: 100%;
    display: flex;
    justify-content: center;

    &>div {
      @extend .w-240;
      @extend .h-48;
    }
  }
}

@media screen and (max-width: $tablet) {
  .repeat_prescription {
    &__buttons {
      width: 100%;
      margin-bottom: $space-ss;
      flex-direction: column;

      &>div {
        width: 100%;
        margin-bottom: $space-s;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .repeat_prescription {
    // margin-top: $space-l;

    &__content {
      width: 100%;
      padding-left: $space-s;
      padding-right: $space-s;
      justify-content: flex-start;
    }

    &__title {
      align-items: flex-start;
      text-align: left;

      h1 {
        font-family: $serif;
        font-weight: bold;
        font-size: 2.25rem;
        width: 100%;
      }

      .p5 {
        width: 100%;
      }
    }
  }
}